import React, { useState } from "react";
import loginbg from "../assets/loginbg.png";
import {
	getAuth,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import Lottie from "lottie-react";
import loaderanimation from "../assets/loaderanimation.json";
import { firebaseConfig } from "../Firebase";
const SignUp = () => {
    const auth = getAuth();
	const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loader, setLoader] = useState(false);
    const [err, setErr] = useState("");
	initializeApp(firebaseConfig);
    const Register = () => {
        setLoader(true);
		createUserWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				// Signed in
				// ...
                console.log("user created ");
                login();
			})
			.catch((error) => {
				const errorCode = error.code;
                const errorMessage = error.message;
                 setLoader(false);
				// ..
			});
    };
    const login = () => {
			setLoader(true);
			signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    setLoader(false);
                    window.location.href="/"
					// Signed in
					// ...
				})
                .catch((error) => {
                    setLoader(false);
					const errorCode = error.code;
                    const errorMessage = error.message;
                    setErr(error.message);
					console.log("error");
				});
		};
	return (
		<div className="bg-white flex justify-center w-screen max-w-none py-32 px-2 lg:px-0 overflow-scroll max-h-screen relative">
			<div className="w-screen absolute top-0 right-0 left-0 bottom-0 bg-cover bg-[url('./assets/loginbg.png')]"></div>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					Register();
				}}
				className="lg:px-16 px-6 lg:py-8 py-4 flex flex-col text-sm lg:text-base gap-4 lg:gap-8 bg-white/60 lg:w-1/3 w-full z-40"
			>
				<div className="flex flex-col gap-4">
					<span>Welcome!</span>
					<span>Sign Up to create your profile</span>
				</div>
				<div className="flex flex-col gap-1">
					<label>Email</label>
					<input
						className="p-2 w-full border-black border rounded-sm"
						type="email"
						placeholder="Enter your email"
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
						}}
						required
					/>
				</div>
				<div className="flex flex-col gap-1">
					<label>Password</label>
					<input
						className="p-2 w-full border-black border rounded-sm"
						type="password"
						placeholder="Enter your password "
						value={password}
						onChange={(e) => {
							setPassword(e.target.value);
						}}
						required
					/>
				</div>
				{loader ? (
					<span
						type="submit"
						className="bg-[#E5485B] rounded-sm w-full text-white p-2"
					>
						<Lottie
							animationData={loaderanimation}
							loop={true}
							className="h-[2rem]"
						/>
					</span>
				) : (
					<button
						type="submit"
						className="bg-[#E5485B] rounded-sm w-full text-white p-2"
					>
						SIGN UP
					</button>
				)}

				{/* <button className="bg-[black] rounded-sm w-full text-white p-2">
					SIGN UP WITH GOOGLE
				</button> */}
				<div className="w-full flex items-center justify-center">
					<span>
						Already have an account?{" "}
						<a
							href="/login/"
							target="_self"
							className="text-[#004DE5] cursor-pointer"
						>
							Sign In
						</a>{" "}
					</span>
				</div>
			</form>
		</div>
	);
};
export default SignUp;
