import React from "react";
import fold2 from "../assets/kitchen.png";
import people from "../assets/people.svg";
import Footer from "../components/Footer";
import banner from "../assets/banner.png";
import Navbar from "../components/Navbar";
import register from "../assets/final.svg";
import MobileRegister from "../assets/mobileRegister.svg";
import MovingComponent from "react-moving-text";
import thumbnail from "../assets/thumbnail.svg";
import letskeep from "../assets/Keepit.png";

const HomePage = () => {
    return (
			<div className="w-screen relative">
				<Navbar />
				{/* body starts */}
				<div className="w-screen relative lg:h-full gap-4 lg:gap-0 flex lg:flex-row flex-col justify-between lg:justify-center bg-cover lg:my-0 items-center bg-[#E5485B]">
					<div className=" flex flex-col gap-4 items-center lg:mb-72 lg:pt-40  mt-10">
						<span className="text-2xl lg:text-7xl text-white  text-center px-4">
							<span className="font-semibold">Your story is your power,</span>
							<br />
							<span className="font-bold">Shape the world with it.</span>
						</span>
						<span className="text-sm lg:px-0 px-4 lg:text-xl text-white text-center">
							Whether complete, in the making, or just a draft.
							<br /> Share your life, your journey, your unique narrative. We're
							here to listen.
						</span>
						<div className="mt-4 ">
							<a
								href="https://docs.google.com/forms/d/e/1FAIpQLScjltKZkKD2JfQFWgCUUozlN9-ybpxLyFmULY9DjAgOe0E1Pw/viewform?usp=sf_link"
								target="_blank"
								className="hover:px-8 hover:py-3 hover:rounded-full hover:bg-[#1A1A1A] text-base lg:text-xl text-white font-semibold mt-2 py-2 border-b z-50 border-white hover:border-none cursor-pointer"
							>
								Share your story
							</a>
						</div>
					</div>
					<div className="w-screen z-40 flex lg:hidden">
						<img
							src={fold2}
							className="z-30 w-full lg:-mt-72 border-t border-[#E5485B] "
						/>
					</div>
				</div>
				<div className="w-screen z-40 hidden lg:flex">
					<img
						src={fold2}
						className="z-40 w-full lg:-mt-64 border-t border-[#E5485B] "
					/>
				</div>
				{/* body ends  */}

				<div className="w-full flex items-center justify-center lg:py-20 py-4">
					<span className="text-2xl lg:text-7xl text-black tracking-wide my-auto font-bold text-center">
						The World is a Colorful Place
					</span>
				</div>

				<div className="w-screen max-h-[4rem] flex justify-center items-center relative overflow-hidden">
					{/* <img src={banner} className="w-full h-full sticky left-0 right-0" /> */}
					<div className="bg-gradient-to-r  from-red-400 via-red-200 to-purple-400 py-2 lg:py-8 w-screen">
						<img src={letskeep} />
						{/* <MovingComponent
							type="fadeOutToRight"
							duration="4000ms"
							delay="0s"
							direction="normal"
							timing="ease"
							iteration="infinite"
							fillMode="none"
							className="w-full flex items-center justify-between "
						>
							<span>let's keep it that way </span>
							<span>let's keep it that way </span>
							<span>let's keep it that way </span>
							<span>let's keep it that way </span>
						</MovingComponent> */}
					</div>
				</div>

				<div className="w-full py-12 flex justify-center relative px-4 lg:px-32">
					<img src={people} className="w-screen object-fill" />
				</div>

				<div className="w-full flex justify-center py-20 px-4 pt-4">
					<div className="flex flex-col items-center justify-center w-full gap-12 max-w-4xl">
						<div className="w-full flex flex-col gap-4 items-center">
							<span className="text-sm lg:text-xl text-center">
								An interactive and immersive experience of self-exploration,
								community bonding,
								<br className="hidden lg:flex" /> uninhibited rejuvenation,
								intimate socialising, self-expression and desi queer pride
								<br className="hidden lg:flex" /> reclamation. We celebrate the
								unapologetic individuality that defines our sheer existence.
							</span>
							<div className="flex gap-2 items-center group">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="15"
									viewBox="0 0 16 15"
									fill="none"
									className="group-hover:rotate-[45deg] transition-all duration-150"
								>
									<path
										d="M15.0374 7.50006C15.0389 7.70512 14.9766 7.90556 14.859 8.07356C14.7414 8.24156 14.5744 8.36878 14.3812 8.43756L10.3874 9.88756L8.93744 13.8813C8.8664 14.0727 8.7385 14.2377 8.57091 14.3543C8.40332 14.4709 8.20407 14.5333 7.99994 14.5333C7.7958 14.5333 7.59656 14.4709 7.42897 14.3543C7.26138 14.2377 7.13348 14.0727 7.06244 13.8813L5.61244 9.88756L1.61869 8.43756C1.42731 8.36653 1.26226 8.23862 1.1457 8.07103C1.02915 7.90344 0.966675 7.7042 0.966675 7.50006C0.966675 7.29593 1.02915 7.09668 1.1457 6.92909C1.26226 6.7615 1.42731 6.6336 1.61869 6.56256L5.61244 5.11256L7.06244 1.11881C7.13348 0.927435 7.26138 0.762382 7.42897 0.645826C7.59656 0.529271 7.7958 0.466797 7.99994 0.466797C8.20407 0.466797 8.40332 0.529271 8.57091 0.645826C8.7385 0.762382 8.8664 0.927435 8.93744 1.11881L10.3874 5.11256L14.3812 6.56256C14.5744 6.63134 14.7414 6.75856 14.859 6.92656C14.9766 7.09456 15.0389 7.295 15.0374 7.50006Z"
										fill="#1A1A1A"
									/>
								</svg>
								<a
									href="/tokri"
									target="_self"
									className="text-base lg:text-xl font-semibold group-hover:border-b group-hover:border-black"
								>
									Explore
								</a>
							</div>
						</div>
					</div>
				</div>

				<div className="py-8 w-full flex flex-col items-center justify-center gap-4">
					<span className="text-base lg:text-2xl ">
						{" "}
						What to expect at Patang
					</span>
					<h1 className="text-2xl lg:text-6xl font-bold text-center">
						Be Part of the Kaand
					</h1>
					<span className="text-center text-base lg:text-2xl text-[#353535]">
						We invite, encourage, support and
						<br className="lg:hidden flex" /> celebrate you as you are.
					</span>
				</div>

				<div className="w-full px-4 lg:px-20 py-4">
					<div className="w-auto py-20 flex justify-between items-center object-fill bg-cover	bg-inherit rounded-xl px-4 lg:px-32 md:px-12 bg-[url('./assets/Noise.png')]">
						<div className="flex flex-col gap-4">
							<h1 className="text-2xl lg:text-6xl font-bold">Tokri</h1>
							<span className="max-w-3xl md:text-xl text-base font-medium">
								<span className="font-bold md:text-xl text-base">
									An idea of queer rest and rejuvenation
								</span>{" "}
								where you reside, bond, and explore.
								<br /> This immersive experience includes workshops, talks, and
								the magic of shared
								<br /> narratives, led by both program residents and influential
								queer voices.
							</span>

							<span className="font-bold md:text-xl text-base mt-2">
								Join us in the same tokri for 3 days.
							</span>
							<div className="flex gap-2 items-center mt-2 group w-fit">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="15"
									viewBox="0 0 16 15"
									fill="none"
									className="group-hover:rotate-[45deg] transition-all duration-150"
								>
									<path
										d="M15.0374 7.50006C15.0389 7.70512 14.9766 7.90556 14.859 8.07356C14.7414 8.24156 14.5744 8.36878 14.3812 8.43756L10.3874 9.88756L8.93744 13.8813C8.8664 14.0727 8.7385 14.2377 8.57091 14.3543C8.40332 14.4709 8.20407 14.5333 7.99994 14.5333C7.7958 14.5333 7.59656 14.4709 7.42897 14.3543C7.26138 14.2377 7.13348 14.0727 7.06244 13.8813L5.61244 9.88756L1.61869 8.43756C1.42731 8.36653 1.26226 8.23862 1.1457 8.07103C1.02915 7.90344 0.966675 7.7042 0.966675 7.50006C0.966675 7.29593 1.02915 7.09668 1.1457 6.92909C1.26226 6.7615 1.42731 6.6336 1.61869 6.56256L5.61244 5.11256L7.06244 1.11881C7.13348 0.927435 7.26138 0.762382 7.42897 0.645826C7.59656 0.529271 7.7958 0.466797 7.99994 0.466797C8.20407 0.466797 8.40332 0.529271 8.57091 0.645826C8.7385 0.762382 8.8664 0.927435 8.93744 1.11881L10.3874 5.11256L14.3812 6.56256C14.5744 6.63134 14.7414 6.75856 14.859 6.92656C14.9766 7.09456 15.0389 7.295 15.0374 7.50006Z"
										fill="#1A1A1A"
									/>
								</svg>
								<a
									href="/tokri"
									target="_self"
									className=" md:text-2xl text-base font-semibold max-h-fit overflow-visible group-hover:border-b border-black"
								>
									Explore
								</a>
							</div>
						</div>
					</div>
				</div>

				<div className="w-full lg:px-20 px-4">
					<div className="flex gap-4 lg:flex-row flex-col">
						<div className=" lg:w-1/2 py-20 flex flex-col gap-4 justify-between object-fill bg-cover bg-inherit px-4 lg:px-32 md:px-12 rounded-xl bg-[url('./assets/Noise2.png')]">
							<span className="lg:text-6xl text-2xl font-bold">Baawre</span>
							<span className="md:text-xl text-base">
								<span className="font-bold">
									Where Desi Queer Hearts Connect.
								</span>{" "}
								An intimate, judgment and care-free setting curated for
								explorations of desires and needs to connect, bond and couple.
							</span>
							<span className="font-bold md:text-xl text-base">
								Be found in baawron ki basti.
							</span>
							<div className="flex gap-2 items-center mt-2 group">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="15"
									viewBox="0 0 16 15"
									fill="none"
									className="group-hover:rotate-[45deg] transition-all duration-150"
								>
									<path
										d="M15.0374 7.50006C15.0389 7.70512 14.9766 7.90556 14.859 8.07356C14.7414 8.24156 14.5744 8.36878 14.3812 8.43756L10.3874 9.88756L8.93744 13.8813C8.8664 14.0727 8.7385 14.2377 8.57091 14.3543C8.40332 14.4709 8.20407 14.5333 7.99994 14.5333C7.7958 14.5333 7.59656 14.4709 7.42897 14.3543C7.26138 14.2377 7.13348 14.0727 7.06244 13.8813L5.61244 9.88756L1.61869 8.43756C1.42731 8.36653 1.26226 8.23862 1.1457 8.07103C1.02915 7.90344 0.966675 7.7042 0.966675 7.50006C0.966675 7.29593 1.02915 7.09668 1.1457 6.92909C1.26226 6.7615 1.42731 6.6336 1.61869 6.56256L5.61244 5.11256L7.06244 1.11881C7.13348 0.927435 7.26138 0.762382 7.42897 0.645826C7.59656 0.529271 7.7958 0.466797 7.99994 0.466797C8.20407 0.466797 8.40332 0.529271 8.57091 0.645826C8.7385 0.762382 8.8664 0.927435 8.93744 1.11881L10.3874 5.11256L14.3812 6.56256C14.5744 6.63134 14.7414 6.75856 14.859 6.92656C14.9766 7.09456 15.0389 7.295 15.0374 7.50006Z"
										fill="#1A1A1A"
									/>
								</svg>
								<a
									href="/baawre"
									target="_self"
									className="text-base md:text-2xl font-semibold group-hover:border-b border-black"
								>
									Explore
								</a>
							</div>
						</div>
						<div className="lg:w-1/2 py-20 flex-col gap-4 flex justify-between object-fill bg-cover bg-inherit px-4 lg:px-32 md:px-12 rounded-xl bg-[url('./assets/Noise3.png')]">
							<span className="lg:text-6xl text-2xl font-bold">Mastaane</span>
							<span className="md:text-xl text-base">
								<span className="font-bold mt-2">
									Festivity of pride and free being.
								</span>{" "}
								A day of dance, music, drag and booze. A toast to gay pride, a
								rebellion that's pure revelry. Be mastaane apni dhun ke, apne
								rang ke.
							</span>
							<span className="font-bold md:text-xl text-base">
								Be mastaaneapni dhun ke,apne rang ke.
							</span>
							<div className="flex gap-2 items-center mt-2 group">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="15"
									viewBox="0 0 16 15"
									fill="none"
									className="group-hover:rotate-[45deg] transition-all duration-150"
								>
									<path
										d="M15.0374 7.50006C15.0389 7.70512 14.9766 7.90556 14.859 8.07356C14.7414 8.24156 14.5744 8.36878 14.3812 8.43756L10.3874 9.88756L8.93744 13.8813C8.8664 14.0727 8.7385 14.2377 8.57091 14.3543C8.40332 14.4709 8.20407 14.5333 7.99994 14.5333C7.7958 14.5333 7.59656 14.4709 7.42897 14.3543C7.26138 14.2377 7.13348 14.0727 7.06244 13.8813L5.61244 9.88756L1.61869 8.43756C1.42731 8.36653 1.26226 8.23862 1.1457 8.07103C1.02915 7.90344 0.966675 7.7042 0.966675 7.50006C0.966675 7.29593 1.02915 7.09668 1.1457 6.92909C1.26226 6.7615 1.42731 6.6336 1.61869 6.56256L5.61244 5.11256L7.06244 1.11881C7.13348 0.927435 7.26138 0.762382 7.42897 0.645826C7.59656 0.529271 7.7958 0.466797 7.99994 0.466797C8.20407 0.466797 8.40332 0.529271 8.57091 0.645826C8.7385 0.762382 8.8664 0.927435 8.93744 1.11881L10.3874 5.11256L14.3812 6.56256C14.5744 6.63134 14.7414 6.75856 14.859 6.92656C14.9766 7.09456 15.0389 7.295 15.0374 7.50006Z"
										fill="#1A1A1A"
									/>
								</svg>
								<button className="text-base md:text-2xl font-semibold group-hover:border-b border-black">
									Explore
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="w-full px-4 lg:px-12 py-10 mt-10 bg-[#FFBCBC]">
					<div className="w-full flex flex-col items-center gap-4">
						<span className="text-sm lg:text-2xl">
							One story summons many more.{" "}
						</span>
						<h1 className="text-2xl lg:text-6xl font-bold">
							Josh Talks X Patang
						</h1>
						<span className="text-[#353535] text-base md:text-2xl text-center">
							We see power of change and josh of being in you and your story
						</span>
						{/* <iframe
							className="w-1/2 mt-4 rounded-2xl hidden lg:flex"
							src="http://www.youtube.com/watch?v=4I3t74fbhCE&t=217s&ab_channel=JoshTalks%E0%A4%AA%E0%A4%A4%E0%A4%82%E0%A4%97"
							frameborder="0"
							allowfullscreen
						/> */}
						<iframe
							width="1038"
							height="584"
							className="w-3/4 mt-4 rounded-2xl hidden lg:block"
							src="https://www.youtube.com/embed/4I3t74fbhCE"
							title="A Lesbian&#39;s Tale of Breaking Free from Society&#39;s Expectations | #JoshTalksPatang"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
						></iframe>
						<iframe
							width="1038"
							height="284"
							className="w-full mt-4 rounded-2xl lg:hidden block"
							src="https://www.youtube.com/embed/4I3t74fbhCE"
							title="A Lesbian&#39;s Tale of Breaking Free from Society&#39;s Expectations | #JoshTalksPatang"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
						></iframe>
						{/* <iframe
							height="584"
							className="w-1/2 mt-4 rounded-2xl hidden lg:flex"
							src="https://www.youtube.com/watch?v=4I3t74fbhCE&t=217s"
							title="I Feared Revealing My True Identity | Zoya Thomas Lobo | Josh Talks"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
						></iframe>
						<img src={thumbnail} className="lg:hidden" /> */}
					</div>
				</div>
				<div className="lg:py-8"></div>
				<div className="w-full lg:px-12 px-4 py-8 lg:py-8 relative lg:h-[44rem]">
					<div className=" flex flex-col gap-6 md:items-start items-center lg:max-w-xl lg:ml-24 mt-8">
						<span className="text-2xl lg:text-6xl text-black tracking-wide font-bold ">
							Explore, Express.
						</span>
						<span className="text-base md:text-left text-center lg:text-xl font-medium">
							Be yourself, connect with an empowering
							<br /> community, and make lifelong allies.
						</span>
						<a
							href="https://forms.gle/s2NgXREUqnK1EtPB9"
							target="_blank"
							className="px-6 lg:text-lg text-base hover:bg-[#1A1A1A] py-3 w-fit bg-[#E5485B] font-semibold rounded-full text-white"
						>
							Register Now
						</a>
						<img
							className="relative lg:hidden top-0 w-full h-auto"
							src={MobileRegister}
						/>
					</div>
					<img
						className="absolute hidden lg:flex top-0 w-fit left-[16rem] h-auto"
						src={register}
					/>
				</div>
				<Footer />
			</div>
		);
}
export default HomePage;


