import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import register from "../assets/final.svg";
import MobileRegister from "../assets/mobileRegister.svg";
import calendar from "../assets/calendar.svg";
import letter from "../assets/letter.svg";
import fullimage from "../assets/fullimage.svg";
import secondimage from "../assets/secondimage.svg";
import love from "../assets/love.png";
import Tokri from "../assets/tokri.png";
import quote from "../assets/quote.png";
import line from "../assets/linee.png";
import grpq from "../assets/groupq.png";
import hero from "../assets/herotemplates.png";
import tokrihorizontal from "../assets/gifs/Tokrihorizontal.gif";
import tokriVertical from "../assets/gifs/tokriVertical.gif";

const Mastaane = () => {
	return (
		<>
			<Navbar />
			<div className="flex  lg:flex-row flex-col w-screen justify-between h-full py-8 lg:py-28 bg-[#E5485B]">
				<div className="flex relative flex-col text-white gap-4 lg:gap-8 lg:px-24 px-4">
					<span className="text-xl font-semibold">RESIDENCY</span>
					<h1 className="lg:text-9xl text-2xl font-bold font-sans">Tokri</h1>
					<span className="text-xl">
						An idea of queer rest and rejuvenation
						<br /> where you reside, bond, and explore.
					</span>
					<span className="text-xl font-semibold lg:mt-6">
						workshops | talks | shared narratives
					</span>
					<span className="text-xl">
						Led by both program residents and influential queer voices.
					</span>
					<a
						href="https://docs.google.com/forms/d/e/1FAIpQLSeauywrvMP2DpeDVG2PpadlzGKx3ECAenw3HMqM5gbCefNUcw/viewform?usp=sf_link"
						target="_blank"
						className="hover:px-4 hover:py-3 w-fit hover:rounded-full hover:bg-[#1A1A1A] text-base lg:text-xl text-white font-semibold mt-2 py-2 border-b z-50 border-white hover:border-none cursor-pointer"
					>
						Apply Now
					</a>
					<div className="absolute left-0 mt-4 top-full bottom-0 lg:flex hidden">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="135"
							height="67"
							viewBox="0 0 135 67"
							fill="none"
						>
							<path
								d="M1.5239 26.2622C8.12814 37.1564 32.8387 70.9575 50.5618 53.6827C55.5815 48.7899 57.7206 35.1299 48.9387 32.2182C37.2281 28.3355 36.0017 46.3558 40.3562 52.7847C51.8276 69.7208 79.0856 67.39 94.5547 58.4597C107.545 50.9603 115.984 37.1935 121.675 23.7631C124.344 17.463 123.853 10.9512 126.08 4.73823C129.527 -4.87337 129.82 9.52773 131.966 14.6628C136.351 25.1551 128.702 8.33865 127.211 4.71232C124.752 -1.27095 111.872 8.53318 106.429 11.4123"
								stroke="#FFBCBC"
								stroke-width="2"
								stroke-linecap="round"
							/>
						</svg>
					</div>
				</div>
				<div className="lg:w-1/2 py-2 w-full flex flex-col justify-end mb-12 ">
					<img
						src={tokrihorizontal}
						height=""
						width=""
						loading="lazy"
						className="hidden lg:block"
					/>
					<img
						src={tokriVertical}
						height=""
						width=""
						loading="lazy"
						className="lg:hidden mt-1"
					/>
				</div>
			</div>
			<div className="w-full lg:px-12 px-4 py-8 lg:py-8 relative lg:h-[44rem]">
				<div className=" flex flex-col gap-6 md:items-start items-center lg:max-w-xl lg:ml-24 mt-8">
					<span className="text-2xl lg:text-6xl text-black tracking-wide font-bold ">
						Love is in the Air,
						<br /> so is Patang
					</span>
					<span>
						Log kya sochenge, ye bhi hum
						<br /> sochenge toh log kya sochenge?
					</span>
					<button className="px-6 lg:text-lg text-base hover:bg-[#1A1A1A] py-3 w-fit bg-[#E5485B] font-semibold rounded-full text-white">
						Register Now
					</button>
					<img
						className="relative lg:hidden top-0 w-full h-auto"
						src={MobileRegister}
					/>
				</div>
				<img
					className="absolute hidden lg:flex top-8 w-fit left-[16rem] h-auto"
					src={register}
				/>
			</div>

			{/* <div className="w-screen bg-[url('./assets/template.svg')] bg-cover py-12 px-24 relative pb-72">
				<div className="flex justify-between">
					<div className="flex justify-between w-full py-12">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="212"
							height="142"
							viewBox="0 0 212 142"
							fill="none"
						>
							<path
								d="M3.40587 139.419C3.40587 139.419 6.50021 109.869 28.5663 93.2109C50.9947 76.2794 92.3769 72.1894 102.393 98.3462C107.781 112.417 105.267 125.057 91.805 131.938C78.3883 138.796 65.0828 134.209 55.6656 122.511C35.5034 97.4657 60.3667 57.3277 88.9939 42.4561C117.679 27.5546 165.676 30.096 174.388 61.1132C178.463 75.6202 175.019 89.3979 161.394 95.9497C148.039 102.372 134.327 98.178 125.255 86.5229C105.899 61.6567 127.269 19.3607 156.578 7.50483C175.715 -0.236341 192.504 1.96597 208.932 14.3722"
								stroke="#E5485B"
								stroke-width="5"
								stroke-linecap="round"
							/>
						</svg>
						<div className="mt-20 pr-20">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="112"
								height="107"
								viewBox="0 0 112 107"
								fill="none"
							>
								<path
									d="M13.6764 3.52051C42.3766 -3.70045 43.6355 45.6112 47.7765 51.2766C51.9174 56.942 79.7582 6.72942 104.693 31.7094C129.627 56.6895 37.2171 106.823 30.3671 103.585C21.8046 99.5389 -15.0237 10.7415 13.6764 3.52051Z"
									stroke="#E5485B"
									stroke-width="5"
									stroke-linecap="round"
								/>
							</svg>
						</div>
					</div>
					<div className="bg-white rounded-2xl p-12 flex flex-col gap-2 w-full">
						<span>RESIDENCY</span>
						<span className="text-4xl font-semibold">GOA 2023</span>
						<span>16 Nov - 19 Nov 20 seats</span>
						<div className="flex justify-between">
							<div className="flex flex-col justify-between">
								<span className="mt-4">
									We invite you to join us in the same Tokri for 3 days
									<br /> as we express and explore ourselves.
								</span>
								<button className="border-b w-fit border-[#E5485B] text-[#E5485B] text-xl py-1">
									Apply now
								</button>
							</div>
							<img src={calendar} className="w-fit bg-cover" />
						</div>
					</div>
				</div>
				<div className="flex justify-between py-20">
					<img src={fullimage} className="w-fit -mt-12" />
					<img src={secondimage} className="w-fit" />
				</div>
				<div className=" flex justify-between bg-transparent absolute left-0 right-0 pb-12">
					<span className="text-5xl font-semibold">R SLOT NOW</span>
					<span className="text-5xl font-semibold">BOOK YOUR SLOT NOW</span>
					<span className="text-5xl font-semibold">BOOK YOUR</span>
				</div>
			</div> */}

			{/* this has been hidden  */}
			{/* <div className="py-6 px-4 lg:py-24 lg:px-36 w-full flex lg:flex-row flex-col items-center gap-4 lg:gap-20 justify-between bg-[#E5485B]">
				<img src={love} className="max-w-[20rem]"/>
				<div className="flex flex-col gap-12 lg:w-1/2 w-full">
					<img src={quote} className="w-fit" />
					<span className="text-xl text-white">
						Tokri has been an absolute revelation for me. As a queer individual,
						finding a space where I can be my authentic self without any
						hesitation or judgment has been incredibly empowering. The blend of
						rejuvenating activities, heartfelt stories, and the opportunity to
						connect with like-minded souls has made every moment at Tokri
						unforgettable.
					</span>
					<div className="flex flex-col text-white  ">
						<span className="text-lg">Palak Singh</span>
						<span className="text-md">Wellness Coach</span>
					</div>
				</div>
			</div> */}
			{/* <div className="flex flex-col py-4 lg:py-12  gap-4">
				<span className=" text-2xl lg:text-5xl font-bold px-4 lg:pl-36">
					Be your unfiltered self and embrace
					<br /> every aspect of your identity.
				</span>
				<span className="text-lg lg:text-2xl justify-end px-4 lg:pl-36">
					Offering you a blend of rejuvenating activities, heartfelt stories,
					and the opportunity to connect with like-minded souls to make every
					moment at Tokri unforgettable.
				</span>
				<div className=" flex justify-end w-screen -ml-2">
					<img src={grpq} />
				</div>
			</div> */}
			{/* till here it has been hidden */}

			<Footer />
		</>
	);
};
export default Mastaane;
