import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import about from "../assets/about.svg";
import terrace from "../assets/terrace_final.png";
import Blog from "../components/Blog";
import Dropdown from "../components/Dropdown";
import language from "../assets/language.svg";
import prachi from "../assets/prachi.png";
import bangaji from "../assets/bangaji.png";
import aashi from "../assets/aashi.png";
import shruti from "../assets/shruti.png";
import jyni from "../assets/jyni.png";
import mansi from "../assets/mansi.png";
import location from "../assets/location.svg";
import sam from "../assets/sam.png";
import samridhi from "../assets/Samridhi.png";
import dabas from "../assets/dabas.png";
import {
	AccordionContent,
	Accordion,
	AccordionItem,
	AccordionTrigger,
} from "../components/Accordion";
const AccordionItems = [
	{
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="43"
				height="43"
				viewBox="0 0 43 43"
				fill="none"
			>
				<path
					d="M0.84668 8.87499C0.84668 4.45671 4.4284 0.875 8.84668 0.875H34.8386C39.2569 0.875 42.8386 4.45672 42.8386 8.875V34.8669C42.8386 39.2852 39.2568 42.8669 34.8386 42.8669H8.84667C4.42839 42.8669 0.84668 39.2852 0.84668 34.8669V8.87499Z"
					fill="white"
				/>
				<path
					d="M20.8301 13.7656C20.8301 13.2133 21.2778 12.7656 21.8301 12.7656H21.8538C22.4061 12.7656 22.8538 13.2133 22.8538 13.7656V29.979C22.8538 30.5313 22.4061 30.979 21.8538 30.979H21.8301C21.2778 30.979 20.8301 30.5313 20.8301 29.979V13.7656Z"
					fill="#E5485B"
				/>
				<path
					d="M12.7354 21.8613C12.7354 21.309 13.1831 20.8613 13.7354 20.8613H29.9487C30.501 20.8613 30.9487 21.309 30.9487 21.8613V21.885C30.9487 22.4373 30.501 22.885 29.9487 22.885H13.7354C13.1831 22.885 12.7354 22.4373 12.7354 21.885V21.8613Z"
					fill="#E5485B"
				/>
			</svg>
		),
		title: "Who founded Patang?",
		text: (
			<>
				Patang is a queer support and celebration platform founded by three
				friends, Prachi, Aashi and Jai.
			</>
		),
	},
	{
		icon: (
			<svg
				width="16"
				height="20"
				viewBox="0 0 16 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1.33337 12.5001C1.33337 12.5001 2.16671 11.6667 4.66671 11.6667C7.16671 11.6667 8.83337 13.3334 11.3334 13.3334C13.8334 13.3334 14.6667 12.5001 14.6667 12.5001V2.50008C14.6667 2.50008 13.8334 3.33341 11.3334 3.33341C8.83337 3.33341 7.16671 1.66675 4.66671 1.66675C2.16671 1.66675 1.33337 2.50008 1.33337 2.50008V12.5001ZM1.33337 12.5001V18.3334"
					stroke="#4D566E"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		title: "How much do the residencies/events cost?",
		text: "Patang organises and hosts a variety of residencies, the charges are determined by location and activities. it covers stay and food, travel cost is resident-responsibility. Our team will reach out to you as soon as you register!",
	},
	{
		icon: (
			<svg
				width="16"
				height="20"
				viewBox="0 0 16 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1.33337 12.5001C1.33337 12.5001 2.16671 11.6667 4.66671 11.6667C7.16671 11.6667 8.83337 13.3334 11.3334 13.3334C13.8334 13.3334 14.6667 12.5001 14.6667 12.5001V2.50008C14.6667 2.50008 13.8334 3.33341 11.3334 3.33341C8.83337 3.33341 7.16671 1.66675 4.66671 1.66675C2.16671 1.66675 1.33337 2.50008 1.33337 2.50008V12.5001ZM1.33337 12.5001V18.3334"
					stroke="#4D566E"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		title: "Who can sign up for the residency?",
		text: "LGBTQIA+ and allies (21 yrs+)",
	},
	{
		icon: (
			<svg
				width="16"
				height="20"
				viewBox="0 0 16 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1.33337 12.5001C1.33337 12.5001 2.16671 11.6667 4.66671 11.6667C7.16671 11.6667 8.83337 13.3334 11.3334 13.3334C13.8334 13.3334 14.6667 12.5001 14.6667 12.5001V2.50008C14.6667 2.50008 13.8334 3.33341 11.3334 3.33341C8.83337 3.33341 7.16671 1.66675 4.66671 1.66675C2.16671 1.66675 1.33337 2.50008 1.33337 2.50008V12.5001ZM1.33337 12.5001V18.3334"
					stroke="#4D566E"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		title: "Is there a membership to the programs at Patang?",
		text: "You can become a part of the Patang community by connecting with us through Instagram and registering on our website. it gives you access to our events, invites to programs, mental health care resources, and referrals.",
	},
	{
		icon: (
			<svg
				width="16"
				height="20"
				viewBox="0 0 16 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1.33337 12.5001C1.33337 12.5001 2.16671 11.6667 4.66671 11.6667C7.16671 11.6667 8.83337 13.3334 11.3334 13.3334C13.8334 13.3334 14.6667 12.5001 14.6667 12.5001V2.50008C14.6667 2.50008 13.8334 3.33341 11.3334 3.33341C8.83337 3.33341 7.16671 1.66675 4.66671 1.66675C2.16671 1.66675 1.33337 2.50008 1.33337 2.50008V12.5001ZM1.33337 12.5001V18.3334"
					stroke="#4D566E"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		title: "Do you have counselling services?",
		text: "We do not provide counselling ourselves but we do have an extensive database of all professionals including doctors, councilors, physiologists etc. Please write to us if you would like to get in touch with them. ",
	},
	{
		icon: (
			<svg
				width="16"
				height="20"
				viewBox="0 0 16 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1.33337 12.5001C1.33337 12.5001 2.16671 11.6667 4.66671 11.6667C7.16671 11.6667 8.83337 13.3334 11.3334 13.3334C13.8334 13.3334 14.6667 12.5001 14.6667 12.5001V2.50008C14.6667 2.50008 13.8334 3.33341 11.3334 3.33341C8.83337 3.33341 7.16671 1.66675 4.66671 1.66675C2.16671 1.66675 1.33337 2.50008 1.33337 2.50008V12.5001ZM1.33337 12.5001V18.3334"
					stroke="#4D566E"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		title: "Can non-queer people join patang?",
		text: "Yes, queer-allies, above 21 years of age, are as welcome as queer people to Patang. We wish to maintain a diverse, nurturing and supportive community- non-queer, LGBTQIA+ community supporters are invited to join Patang.",
	},
];
const blogs = [
	{
		name: "Jandeep Banga",
		position: "CFO & Co-founder",
		message: (
			<span>
				He/Him
				<br /> A doctor and entrepreneur involved in a variety of ventures, he
				is the fine wine of our strange concoction.
			</span>
		),
		image: bangaji,
		link: [
			"https://m.facebook.com/profile.php/?id=690440571",
			"https://instagram.com/jandeepb?igshid=NGVhN2U2NjQ0Yg==",
			"",
		],
	},
	{
		name: "Aashi Jain",
		position: "CMO & Co-founder",
		message: (
			<span>
				She/Her
				<br /> An architect who takes her jhumkas and thumkas pretty seriously.
				She is who keeps us desi at heart.
			</span>
		),
		image: aashi,
		link: [
			"https://www.facebook.com/aashi.moulie?mibextid=LQQJ4d",
			"https://instagram.com/aashijain95?igshid=OGQ5ZDc2ODk2ZA==",
			"https://www.linkedin.com/in/aashi-jain-3828939a",
		],
	},
	{
		name: "Prachi Jain",
		position: "CEO & Co-founder",
		message: (
			<span>
				She/Her
				<br /> A communication designer curiously monkeying around and the
				reason why we don’t work on MS Paint.
			</span>
		),
		image: prachi,
		link: [
			"https://www.facebook.com/profile.php?id=100011028722933",
			"https://instagram.com/curious_monkeying?igshid=OGQ5ZDc2ODk2ZA==",
			"https://www.linkedin.com/in/prachi-jain-b619321a5/",
		],
	},
	{
		name: "Shruti Madheshia",
		position: "Marketing manager",
		message: (
			<span>
				She/Her
				<br /> A mast maula, dilbar diwaani, rockin' & rolling the world of
				marketing. She takes her camera and commitment seriously.
			</span>
		),
		image: shruti,
		link: [
			"https://www.facebook.com/profile.php?id=100005831655138&mibextid=LQQJ4d",
			"https://instagram.com/shruti_madheshia?igshid=OGQ5ZDc2ODk2ZA==",
			"https://www.linkedin.com/in/shruti-madheshia-ba4452121",
		],
	},
	{
		name: "Jyni Verma",
		position: "ResearchER",
		message: (
			<span>
				They/Them
				<br /> A sociology graduate with glittery eyes and animated rants,
				they'll do the work, but never at a sane hour.
			</span>
		),
		image: jyni,
		link: [
			"",
			"https://instagram.com/thehurricaneandtherainbow?igshid=MzRlODBiNWFlZA==",
			"https://www.linkedin.com/in/jyni-verma-b06b22267",
		],
	},
	{
		name: "Mansi Gaur",
		position: "ResearchER",
		message: (
			<span>
				She/Her
				<br /> A social researcher who is very sweet and values voice, right to
				speak and responsibility to listen.
			</span>
		),
		image: mansi,
		link: [
			"",
			"https://instagram.com/mansi._gaur?igshid=NjIwNzIyMDk2Mg==",
			"https://www.linkedin.com/in/mansi-gaur-2805b0189/",
		],
	},
	{
		name: "Samridhi Samal",
		position: "Graphic Designer",
		message: (
			<span>
				She/Her
				<br />A designer with paper hoarding issues. Her manifestation works 21%
				of the time, ironically the same as her battery life.
			</span>
		),
		image: samridhi,
		link: [
			"",
			"https://www.instagram.com/samridhisamal/",
			"https://www.linkedin.com/in/samridhisamal/",
		],
	},
	{
		name: "Prachi Dabas",
		position: "Video Editor",
		message: (
			<span>
				She/Her
				<br /> A Video editor who speaks ironically, edits iconically.
			</span>
		),
		image: dabas,
		link: ["", "https://www.instagram.com/dickerectioner/", ""],
	},
	{
		name: "Samridhi Jain",
		position: "Lawyer",
		message: (
			<span>

				An extremely hardworking Supreme Court lawyer, but disturb her on a
				Sunday and you will be prosecuted.
			</span>
		),
		image: sam,
		link: [
			"https://www.facebook.com/samridhi.jain.5249?mibextid=ZbWKwL",
			"https://instagram.com/samridhijain21?igshid=OGQ5ZDc2ODk2ZA==",
			"",
		],
	},
];
const Aboutus = () => {
	const [imageIndex, setImageIndex] = useState(0);
	const url = window.location.href;
	 useEffect(() => {
			if (url.includes("#contact")) {
				window.location.href = "#contact";
			}
		}, [url]);
	
    return (
			<>
				<Navbar />
				<div className="pt-4 lg:pt-24 px-12 flex flex-col w-screen items-center bg-[#FFBCBC] gap-8 relative">
					<span className="text-lg text-center lg:text-2xl">
						We are inviting, encouraging, supporting you in
					</span>
					<span className="text-2xl text-center lg:text-7xl font-bold">
						{" "}
						Celebrating You As You Are
					</span>
					<span className="text-lg text-center lg:text-2xl max-w-5xl">
						Patang celebrates the unfiltered individuality that radiates from
						every queer soul. We foster
						<br /> this spirit through interactive platforms that encourage
						self-expression, connection, and
						<br /> networking with our vibrant community and allies.
					</span>
				<a	
					href="/baawre"
					target="_self"
						className="hover:px-8  hover:py-3 hover:rounded-full hover:bg-[#1A1A1A] relative hover:text-white text-base lg:text-xl text-black font-semibold mt-2 py-3 border-b-2 z-50 border-black hover:border-none cursor-pointer"
					>
						Join the celebration
					</a>
					<div className="w-screen bg-transparent">
						<img src={terrace} className="w-full lg:-mt-20" />
					</div>
				</div>
				<div className="w-screen max-h-[4rem] flex justify-center items-center relative overflow-hidden">
					<div className="bg-gradient-to-r from-red-400 via-red-200 to-purple-400 lg:py-8 w-screen px-4 py-1 lg:px-12 flex justify-between">
						<img src={language} className="" />
					</div>
				</div>
				<div className="w-full px-4 lg:px-20 py-8 lg:py-20 flex lg:flex-row flex-col justify-between items-center">
					<div className="flex flex-col gap-4 overflow-auto h-full">
						<span className="text-2xl hidden lg:flex w-full lg:text-6xl font-semibold leading-none">
							Where Diversity
							<br /> Meets Opportunity
						</span>
						<span className="text-2xl lg:hidden w-full lg:text-6xl font-semibold leading-none">
							Where Diversity Meets Opportunity
						</span>
						<span className="text-lg mt-4 text-[#353535]">
							Join us for a Fun-Filled Journey where Work Feels Like Play,
							<br />
							Connections are as Easy as Conversations, and Every
							<br /> Background Adds a Splash of Color to our Vibrant Work
							Culture.
						</span>
						<a
							href="https://forms.gle/HK7DL9z2vBnEQV6UA"
							target="_blank"
							className="my-8 border-b-2 border-[#E5485B] text-lg w-fit font-bold hover:border-none hover:bg-black hover:text-white hover:py-3 hover:px-4 hover:rounded-full hover:my-5"
						>
							Work with us
						</a>
					</div>
					<img src={about} className="w-auto" />
				</div>
				<div className="px-2 lg:px-12 py-4 lg:py-12">
					<Blog blogs={blogs} />
				</div>
				<div className="px-12 lg:px-48 py-12 flex flex-col gap-12 w-full items-center">
					<div className="flex flex-col gap-4 items-center">
						<span style={{ fontSize: "14px" }}>FREQUENTLY ASKED QUESTIONS</span>
						<span className="text-2xl text-center lg:text-6xl font-bold">
							Answers to some of your questions
						</span>
					</div>
					<Accordion
						defaultValue="item-0"
						type="single"
						collapsible
						className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full"
					>
						{AccordionItems.map((item, index) => (
							<AccordionItem value={`item-${index}`} className="shadow-sm px-4">
								<AccordionTrigger
									onClick={() => setImageIndex(index)}
									className="text-black font-manrope font-medium"
								>
									<span className="flex gap-3 items-center text-xl font-medium">
										{item.title}
									</span>
								</AccordionTrigger>
								<AccordionContent className="text-[#6F6C90] text-lg">
									{item.text}
								</AccordionContent>
							</AccordionItem>
						))}
					</Accordion>
				</div>
				<div
					className="flex lg:flex-row flex-col justify-between py-4 lg:py-12 "
					id="contact"
				>
					<img
						src={location}
						onClick={() => {
							window.open(
								"https://www.google.com/maps/search/Kullu+3332,+Raison+Bihal+Himachal+Pradesh-175128/@32.0548748,77.1272211,16.4z?entry=ttu",
								"_blank"
							);
						}}
						className="w-full cursor-pointer"
					/>
					<div className="flex flex-col gap-8 w-full pl-4 lg:pl-28 bg-[#E5485B] py-16">
						<span className="text-3xl text-white font-semibold">
							Contact Information
						</span>
						<div className="flex gap-8">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
							>
								<path
									d="M25.0008 13.7487H27.5008C27.5008 7.33625 22.6595 2.5 16.2383 2.5V5C21.3158 5 25.0008 8.67875 25.0008 13.7487Z"
									fill="white"
								/>
								<path
									d="M16.2504 10.0001C18.8791 10.0001 20.0004 11.1213 20.0004 13.7501H22.5004C22.5004 9.7188 20.2816 7.50005 16.2504 7.50005V10.0001ZM20.5279 16.8038C20.2877 16.5855 19.972 16.4691 19.6476 16.4791C19.3232 16.4891 19.0154 16.6248 18.7891 16.8576L15.7979 19.9338C15.0779 19.7963 13.6304 19.3451 12.1404 17.8588C10.6504 16.3676 10.1991 14.9163 10.0654 14.2013L13.1391 11.2088C13.3721 10.9827 13.508 10.6748 13.5181 10.3503C13.5281 10.0258 13.4115 9.71011 13.1929 9.47005L8.5741 4.3913C8.35541 4.1505 8.05145 4.00443 7.72679 3.98413C7.40214 3.96382 7.08235 4.07088 6.83535 4.28255L4.12285 6.6088C3.90674 6.8257 3.77775 7.11437 3.76035 7.42005C3.7416 7.73255 3.3841 15.1351 9.1241 20.8776C14.1316 25.8838 20.4041 26.2501 22.1316 26.2501C22.3841 26.2501 22.5391 26.2426 22.5804 26.2401C22.886 26.2229 23.1745 26.0934 23.3904 25.8763L25.7154 23.1626C25.9272 22.9157 26.0345 22.596 26.0144 22.2714C25.9943 21.9467 25.8485 21.6427 25.6079 21.4238L20.5279 16.8038Z"
									fill="white"
								/>
							</svg>
							<span className="text-white text-xl	">+91-7000273052</span>
						</div>
						<a
							href="mailto:contact.patang@gmail.com"
							className="flex gap-8 cursor-pointer"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
							>
								<path
									d="M27.5 5H2.5V25H27.5V5ZM25 10L15 16.25L5 10V7.5L15 13.75L25 7.5V10Z"
									fill="white"
								/>
							</svg>
							<span className="text-white text-xl">
								contact.patang@gmail.com
							</span>
						</a>
						<div className="flex gap-8">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
							>
								<path
									d="M15 1.875C12.266 1.87823 9.6448 2.96575 7.71153 4.89902C5.77826 6.83229 4.69074 9.45344 4.68751 12.1875C4.68424 14.4218 5.41406 16.5954 6.76501 18.375C6.76501 18.375 7.04626 18.7453 7.0922 18.7987L15 28.125L22.9116 18.7941C22.9528 18.7444 23.235 18.375 23.235 18.375L23.2359 18.3722C24.5862 16.5934 25.3157 14.4207 25.3125 12.1875C25.3093 9.45344 24.2218 6.83229 22.2885 4.89902C20.3552 2.96575 17.7341 1.87823 15 1.875ZM15 15.9375C14.2583 15.9375 13.5333 15.7176 12.9166 15.3055C12.2999 14.8935 11.8193 14.3078 11.5355 13.6226C11.2516 12.9373 11.1774 12.1833 11.3221 11.4559C11.4668 10.7285 11.8239 10.0603 12.3484 9.53585C12.8728 9.0114 13.541 8.65425 14.2684 8.50955C14.9959 8.36486 15.7499 8.43912 16.4351 8.72295C17.1203 9.00678 17.706 9.48743 18.118 10.1041C18.5301 10.7208 18.75 11.4458 18.75 12.1875C18.7488 13.1817 18.3533 14.1348 17.6503 14.8378C16.9473 15.5408 15.9942 15.9363 15 15.9375Z"
									fill="white"
								/>
							</svg>
							<span className="text-white text-xl">
								Kullu 3332, Raison Bihal
								<br /> Himachal Pradesh-175128
							</span>
						</div>
					</div>
				</div>
				<div className="px-4 lg:px-12 py-12 w-full flex flex-col gap-8 relative items-center">
					<span className="text-xl lg:text-6xl font-bold">
						Let’s hear what you have to say
					</span>
					<div className="flex flex-col gap-10 border-gray-50 border shadow-sm max-w-4xl w-full px-6 py-8">
						<input
							className="border-b border-gray-200 w-full placeholder-gray-400 outline-none py-1 px-3"
							placeholder="Your name"
						/>
						<div className="flex gap-4">
							<input
								className="border-b border-gray-200 w-full placeholder-gray-400 outline-none py-1 px-3"
								placeholder="Phone"
								type="number"
							/>
							<input
								className="border-b border-gray-200 w-full placeholder-gray-400 outline-none py-1 px-3"
								placeholder="Email"
								type="email"
							/>
						</div>
						<div className="flex gap-4">
							<input
								className="border-b border-gray-200 w-full placeholder-gray-400 outline-none py-1 px-3"
								placeholder="Age"
								type="text"
							/>
							<input
								className="border-b border-gray-200 w-full placeholder-gray-400 outline-none py-1 px-3"
								placeholder="Pronouns"
							/>
						</div>
						<Dropdown
							option="How may we help you"
							fields={[
								"Want to join residency?",
								"Explore dating",
								"Join the fest",
								"Work with us?",
								"Collab and partnerships?",
							]}
						/>
						<Dropdown
							option="Select Country"
							fields={[
								"Afghanistan",
								"Albania",
								"Algeria",
								"American Samoa",
								"Andorra",
								"Angola",
								"Anguilla",
								"Antarctica",
								"Antigua and Barbuda",
								"Argentina",
								"Armenia",
								"Aruba",
								"Australia",
								"Austria",
								"Azerbaijan",
								"Bahamas (the)",
								"Bahrain",
								"Bangladesh",
								"Barbados",
								"Belarus",
								"Belgium",
								"Belize",
								"Benin",
								"Bermuda",
								"Bhutan",
								"Bolivia (Plurinational State of)",
								"Bonaire, Sint Eustatius and Saba",
								"Bosnia and Herzegovina",
								"Botswana",
								"Bouvet Island",
								"Brazil",
								"British Indian Ocean Territory (the)",
								"Brunei Darussalam",
								"Bulgaria",
								"Burkina Faso",
								"Burundi",
								"Cabo Verde",
								"Cambodia",
								"Cameroon",
								"Canada",
								"Cayman Islands (the)",
								"Central African Republic (the)",
								"Chad",
								"Chile",
								"China",
								"Christmas Island",
								"Cocos (Keeling) Islands (the)",
								"Colombia",
								"Comoros (the)",
								"Congo (the Democratic Republic of the)",
								"Congo (the)",
								"Cook Islands (the)",
								"Costa Rica",
								"Croatia",
								"Cuba",
								"Curaçao",
								"Cyprus",
								"Czechia",
								"Côte d'Ivoire",
								"Denmark",
								"Djibouti",
								"Dominica",
								"Dominican Republic (the)",
								"Ecuador",
								"Egypt",
								"El Salvador",
								"Equatorial Guinea",
								"Eritrea",
								"Estonia",
								"Eswatini",
								"Ethiopia",
								"Falkland Islands (the) [Malvinas]",
								"Faroe Islands (the)",
								"Fiji",
								"Finland",
								"France",
								"French Guiana",
								"French Polynesia",
								"French Southern Territories (the)",
								"Gabon",
								"Gambia (the)",
								"Georgia",
								"Germany",
								"Ghana",
								"Gibraltar",
								"Greece",
								"Greenland",
								"Grenada",
								"Guadeloupe",
								"Guam",
								"Guatemala",
								"Guernsey",
								"Guinea",
								"Guinea-Bissau",
								"Guyana",
								"Haiti",
								"Heard Island and McDonald Islands",
								"Holy See (the)",
								"Honduras",
								"Hong Kong",
								"Hungary",
								"Iceland",
								"India",
								"Indonesia",
								"Iran (Islamic Republic of)",
								"Iraq",
								"Ireland",
								"Isle of Man",
								"Israel",
								"Italy",
								"Jamaica",
								"Japan",
								"Jersey",
								"Jordan",
								"Kazakhstan",
								"Kenya",
								"Kiribati",
								"Korea (the Democratic People's Republic of)",
								"Korea (the Republic of)",
								"Kuwait",
								"Kyrgyzstan",
								"Lao People's Democratic Republic (the)",
								"Latvia",
								"Lebanon",
								"Lesotho",
								"Liberia",
								"Libya",
								"Liechtenstein",
								"Lithuania",
								"Luxembourg",
								"Macao",
								"Madagascar",
								"Malawi",
								"Malaysia",
								"Maldives",
								"Mali",
								"Malta",
								"Marshall Islands (the)",
								"Martinique",
								"Mauritania",
								"Mauritius",
								"Mayotte",
								"Mexico",
								"Micronesia (Federated States of)",
								"Moldova (the Republic of)",
								"Monaco",
								"Mongolia",
								"Montenegro",
								"Montserrat",
								"Morocco",
								"Mozambique",
								"Myanmar",
								"Namibia",
								"Nauru",
								"Nepal",
								"Netherlands (the)",
								"New Caledonia",
								"New Zealand",
								"Nicaragua",
								"Niger (the)",
								"Nigeria",
								"Niue",
								"Norfolk Island",
								"Northern Mariana Islands (the)",
								"Norway",
								"Oman",
								"Pakistan",
								"Palau",
								"Palestine, State of",
								"Panama",
								"Papua New Guinea",
								"Paraguay",
								"Peru",
								"Philippines (the)",
								"Pitcairn",
								"Poland",
								"Portugal",
								"Puerto Rico",
								"Qatar",
								"Republic of North Macedonia",
								"Romania",
								"Russian Federation (the)",
								"Rwanda",
								"Réunion",
								"Saint Barthélemy",
								"Saint Helena, Ascension and Tristan da Cunha",
								"Saint Kitts and Nevis",
								"Saint Lucia",
								"Saint Martin (French part)",
								"Saint Pierre and Miquelon",
								"Saint Vincent and the Grenadines",
								"Samoa",
								"San Marino",
								"Sao Tome and Principe",
								"Saudi Arabia",
								"Senegal",
								"Serbia",
								"Seychelles",
								"Sierra Leone",
								"Singapore",
								"Sint Maarten (Dutch part)",
								"Slovakia",
								"Slovenia",
								"Solomon Islands",
								"Somalia",
								"South Africa",
								"South Georgia and the South Sandwich Islands",
								"South Sudan",
								"Spain",
								"Sri Lanka",
								"Sudan (the)",
								"Suriname",
								"Svalbard and Jan Mayen",
								"Sweden",
								"Switzerland",
								"Syrian Arab Republic",
								"Taiwan",
								"Tajikistan",
								"Tanzania, United Republic of",
								"Thailand",
								"Timor-Leste",
								"Togo",
								"Tokelau",
								"Tonga",
								"Trinidad and Tobago",
								"Tunisia",
								"Turkey",
								"Turkmenistan",
								"Turks and Caicos Islands (the)",
								"Tuvalu",
								"Uganda",
								"Ukraine",
								"United Arab Emirates (the)",
								"United Kingdom of Great Britain and Northern Ireland (the)",
								"United States Minor Outlying Islands (the)",
								"United States of America (the)",
								"Uruguay",
								"Uzbekistan",
								"Vanuatu",
								"Venezuela (Bolivarian Republic of)",
								"Viet Nam",
								"Virgin Islands (British)",
								"Virgin Islands (U.S.)",
								"Wallis and Futuna",
								"Western Sahara",
								"Yemen",
								"Zambia",
								"Zimbabwe",
								"Åland Islands",
							]}
						/>
						<input
							className="border-b border-gray-200 w-full placeholder-gray-400 outline-none py-1 px-3"
							placeholder="How else may we help you? Let us know.(Optional)"
						/>
						<button className="px-4 hover:bg-black hover:text-white py-2 mx-auto bg-[#E5485B] w-fit rounded-full text-white">
							Submit
						</button>
					</div>
					<div className="hidden lg:flex absolute left-0 top-1/4">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="250"
							height="172"
							viewBox="0 0 307 172"
							fill="none"
						>
							<path
								d="M2 170.27C38.069 134.201 86.8396 102.869 138 94.1814C151.912 91.819 219.6 80.0346 219.6 106.27C219.6 118.836 205.517 116.603 198.089 112.67C183.466 104.929 172.569 97.4883 162.356 83.8703C146.59 62.8502 129.259 28.925 152.4 7.0703C162.387 -2.36176 181.496 3.00328 190.8 9.91475C199.922 16.6909 205.992 25.3872 212.844 34.2703C218.414 41.4902 216.919 40.7575 221.2 32.6703C231.946 12.372 266.144 -1.88721 272.044 29.8259C275.355 47.619 269.497 63.0672 264.044 79.4258C261.996 85.5706 258.821 96.2523 254.444 101.115C249.68 106.409 247.099 97.9667 248.755 93.4703C252.874 82.2914 258.436 77.4703 270.444 77.4703C281.305 77.4703 295.714 85.9154 296.4 98.2703C296.781 105.125 298.515 103.448 300.844 98.2703C302.652 94.2535 305.289 81.4211 305.289 85.8259C305.289 87.3777 305.203 100.224 303.689 101.115C298.299 104.285 290.979 104.344 285.2 106.27"
								stroke="#1C1C1C"
								stroke-width="3"
								stroke-linecap="round"
							/>
						</svg>
					</div>
				</div>
				<Footer />
			</>
		);
} 
export default Aboutus;