import React from "react";
import HomePage from "./screens/HomePage";
import Tokri from "./screens/Tokri";
import Baawre from "./screens/Baawre";
import Mastaane from "./screens/Mastaane";
import Aboutus from "./screens/Aboutus";
import Profile from "./screens/Profile";
import Login from "./screens/Login";
import SignUp from "./screens/SignUp";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
function App() {
  return (
		<Router>
			<Routes>
				<Route exact path="/" element={<HomePage />} />
			</Routes>
			<Routes>
				<Route exact path="/tokri" element={<Tokri />} />
			</Routes>
			<Routes>
				<Route exact path="/baawre" element={<Baawre />} />
			</Routes>
			<Routes>
				<Route exact path="/mastaane" element={<Mastaane />} />
			</Routes>
			<Routes>
				<Route exact path="/about-us" element={<Aboutus />} />
			</Routes>
			<Routes>
				<Route exact path="/profile" element={<Profile />} />
			</Routes>
			<Routes>
				<Route exact path="/login" element={<Login />} />
			</Routes>
			<Routes>
				<Route exact path="/signup" element={<SignUp />} />
			</Routes>
		</Router>
	);
}

export default App;
