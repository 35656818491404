import React from "react";
import Dropdown from "../components/Dropdown";
import Footer from "../components/Footer";
import calendar from "../assets/calendar.svg";
import Navbar from "../components/Navbar";
import dots from "../assets/dotsred.png";
const countries = ["India", "USA"]
var QA = [
	{
		Question: "We'd love to get to know you better!",
		placeholder: "Please introduce yourself",
		answer: "",
	},
	{
		Question: "Are you looking to connect for any particular reason",
		placeholder: "Networking, Expressing, Exploring, Dating?",
		answer: "",
	},
	{
		Question: "In any way would you like to contribute to patang?",
		placeholder: "workshops, sing, dance, perform, talks, pop-up shop",
		answer: "",
	},
	{
		Question: "How may we build our journey together?",
		placeholder: "What are you looking to explore, express or do with us ",
		answer: "",
	},
];
const Profile = () => {
    return (
			<>
				<Navbar />
				<div className="py-12 px-16 flex w-full justify-between gap-24">
					<div className="flex flex-col justify-between p-8 pt-16 border w-full border-gray-100 rounded-xl shadow-md ">
						<input
							className="border-b w-full py-2 outline-none border-[#E9E9E9] bg-transparent placeholder-[#9A9A9A]"
							placeholder="Your full name"
						/>
						<input
							className="border-b w-full py-2 outline-none border-[#E9E9E9] bg-transparent placeholder-[#9A9A9A]"
							placeholder="Email"
						/>
						<div className="flex gap-4">
							<input
								className="border-b w-full py-2 outline-none border-[#E9E9E9] bg-transparent placeholder-[#9A9A9A]"
								placeholder="Age"
							/>
							<input
								className="border-b w-full py-2 outline-none border-[#E9E9E9] bg-transparent placeholder-[#9A9A9A]"
								placeholder="Pronouns"
							/>
						</div>
						<input
							className="border-b w-full py-2 outline-none border-[#E9E9E9] bg-transparent placeholder-[#9A9A9A]"
							placeholder="Phone"
						/>
						<Dropdown option="Select Country" />
						<button className="px-4 py-2 bg-[#E5485B] w-fit hover:bg-red-500 rounded-full text-white">
							Done!
						</button>
					</div>
					<div className="w-full h-full">
						<img src={dots} />
					</div>
				</div>
				<div className="px-16 py-8 flex gap-6 justify-between">
					<div className="w-3/4  flex flex-col gap-12">
						{QA.map((k, i) => {
							return (
								<div className="p-6 px-12 border-gray-200 border shadow-sm flex flex-col gap-4 rounded-xl">
									<span className="text-gray-[#9A9A9A]">{k.Question}</span>
									<input
										placeholder={k.placeholder}
										className="placeholder-gray-400 w-full py-1 outline-none border-b border-gray-200"
										value={k.answer}
										onChange={(e) => {
											QA[i].answer = e.target.value;
										}}
									/>
								</div>
							);
						})}
					</div>
					<div className="w-1/4 flex flex-col gap-10">
						<div className="flex flex-col py-4 px-6 gap-6 border-gray-100 border rounded-xl shadow-sm">
							<input
								placeholder="Link to your instagram"
								className="placeholder-gray-400 w-full py-1 outline-none border-b border-gray-200"
							/>
							<input
								placeholder="Link to any other socials"
								className="placeholder-gray-400 w-full py-1 outline-none border-b border-gray-200"
							/>
							<input
								placeholder="Link to any other socials"
								className="placeholder-gray-400 w-full py-1 outline-none border-b border-gray-200"
							/>
						</div>
						<div className="flex flex-col py-4 px-6 gap-6 border-gray-100 border rounded-xl shadow-sm">
							<input
								placeholder="Any allergies and food preferences"
								className="placeholder-gray-400 w-full py-1 outline-none border-b border-gray-200"
							/>
							<input
								placeholder="Any medical conditions"
								className="placeholder-gray-400 w-full py-1 outline-none border-b border-gray-200"
							/>
						</div>
					</div>
				</div>
				{/* <div className="py-12 px-16 flex flex-col gap-10">
					<h1 className="text-4xl">Upcoming Events</h1>
					<div className=" ">
						<div className="flex gap-20 overflow-scroll">
							<div className="bg-white min-w-max rounded-3xl p-12 flex flex-col gap-2 w-fit border border-gray-100 shadow-sm">
								<span>RESIDENCY</span>
								<span className="text-4xl font-semibold">GOA 2023</span>
								<span>16 Nov - 19 Nov 20 seats</span>
								<div className="flex justify-between gap-12">
									<div className="flex flex-col justify-between">
										<span className="mt-4">
											We invite you to join us in the same Tokri for 3 days
											<br /> as we express and explore ourselves.
										</span>
										<button className="border-b w-fit border-[#E5485B] text-[#E5485B] text-xl py-1">
											Applied
										</button>
									</div>
									<img src={calendar} className="w-fit bg-cover" />
								</div>
							</div>
							<div className="bg-white min-w-max  rounded-3xl p-12 flex flex-col gap-2 w-fit border border-gray-100 shadow-sm">
								<span>RESIDENCY</span>
								<span className="text-4xl font-semibold">GOA 2023</span>
								<span>16 Nov - 19 Nov 20 seats</span>
								<div className="flex justify-between gap-12">
									<div className="flex flex-col justify-between">
										<span className="mt-4">
											We invite you to join us in the same Tokri for 3 days
											<br /> as we express and explore ourselves.
										</span>
										<button className="border-b w-fit border-[#E5485B] text-[#E5485B] text-xl py-1">
											Applied
										</button>
									</div>
									<img src={calendar} className="w-fit bg-cover" />
								</div>
							</div>
							<div className="bg-white rounded-3xl min-w-max  p-12 flex flex-col gap-2 w-fit border border-gray-100 shadow-sm">
								<span>DATING</span>
								<span className="text-4xl font-semibold">Oct 2023</span>
								<span>16 Nov - 19 Nov 20 seats</span>
								<div className="flex justify-between gap-12">
									<div className="flex flex-col justify-between">
										<span className="mt-4">
											We invite you to join us in the same Tokri for 3 days
											<br /> as we express and explore ourselves.
										</span>
										<button className="border-b w-fit border-[#E5485B] text-[#E5485B] text-xl py-1">
											Apply now
										</button>
									</div>
									<img src={calendar} className="w-fit bg-cover" />
								</div>
							</div>
						</div>
					</div>
				</div> */}
				<Footer />
			</>
		);
}
export default Profile;