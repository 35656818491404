import React from "react";
import patang from "../assets/patang_white.svg";
const Links = [
	{ field: "About", href: "/about-us" },
	{
		field: "Careers",
		href: "https://docs.google.com/forms/d/e/1FAIpQLSdZ9_XbYxA67yJnRTUmOESr-uccBzPOnBgyJMkrouRSAzPO0g/viewform?usp=sf_link",
	},
	{ field: "Blog", href: "https://medium.com/@contact.patang" },
	{ field: "Contact us", href: "/about-us/#contact" },
];
const Social = [
	{
		name: "Facebook",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="12"
				height="20"
				viewBox="0 0 12 20"
				fill="none"
			>
				<path
					d="M7.65927 19.6593V10.9439H10.7319L11.1919 7.54733H7.65918V5.37877C7.65918 4.39538 7.94597 3.72526 9.4272 3.72526L11.3163 3.72441V0.686556C10.9896 0.645238 9.8681 0.552734 8.56356 0.552734C5.83979 0.552734 3.97506 2.13558 3.97506 5.04248V7.54733H0.894531V10.9439H3.97506V19.6592H7.65927V19.6593Z"
					fill="white"
				/>
			</svg>
		),
		href: "https://www.facebook.com/people/Patang/100094066326999/",
	},
	{
		name: "Instagram",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="39"
				height="39"
				viewBox="0 0 39 39"
				fill="none"
			>
				<rect
					x="0.393555"
					y="-0.000488281"
					width="38.2131"
					height="38.2131"
					rx="8.4918"
					fill="#E5485B"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M9.94824 19.1058C9.94824 15.2896 9.94824 13.3815 10.8576 12.0072C11.2637 11.3935 11.7893 10.8679 12.403 10.4618C13.7773 9.55249 15.6854 9.55249 19.5015 9.55249C23.3177 9.55249 25.2257 9.55249 26.6001 10.4618C27.2138 10.8679 27.7394 11.3935 28.1454 12.0072C29.0548 13.3815 29.0548 15.2896 29.0548 19.1058C29.0548 22.9219 29.0548 24.83 28.1454 26.2043C27.7394 26.818 27.2138 27.3436 26.6001 27.7497C25.2257 28.659 23.3177 28.659 19.5015 28.659C15.6854 28.659 13.7773 28.659 12.403 27.7497C11.7893 27.3436 11.2637 26.818 10.8576 26.2043C9.94824 24.83 9.94824 22.9219 9.94824 19.1058ZM24.4454 19.1058C24.4454 21.8373 22.2311 24.0516 19.4996 24.0516C16.768 24.0516 14.5537 21.8373 14.5537 19.1058C14.5537 16.3742 16.768 14.1599 19.4996 14.1599C22.2311 14.1599 24.4454 16.3742 24.4454 19.1058ZM19.4996 22.3783C21.3069 22.3783 22.7721 20.9131 22.7721 19.1058C22.7721 17.2984 21.3069 15.8332 19.4996 15.8332C17.6922 15.8332 16.227 17.2984 16.227 19.1058C16.227 20.9131 17.6922 22.3783 19.4996 22.3783ZM24.6416 15.074C25.2834 15.074 25.8037 14.5537 25.8037 13.9119C25.8037 13.2701 25.2834 12.7498 24.6416 12.7498C23.9998 12.7498 23.4795 13.2701 23.4795 13.9119C23.4795 14.5537 23.9998 15.074 24.6416 15.074Z"
					fill="white"
				/>
			</svg>
		),
		href: "https://www.instagram.com/patang.co/",
	},
	{
		name: "LinkedIn",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="39"
				height="39"
				viewBox="0 0 39 39"
				fill="none"
			>
				<rect
					x="0.590088"
					y="-0.000244141"
					width="38.2131"
					height="38.2131"
					rx="8.4918"
					fill="#E5485B"
				/>
				<path
					d="M10.1448 12.1213C10.1448 11.5086 10.3599 11.0032 10.7903 10.6049C11.2206 10.2067 11.78 10.0076 12.4685 10.0076C13.1448 10.0076 13.6919 10.2036 14.1099 10.5957C14.5403 11.0001 14.7554 11.527 14.7554 12.1765C14.7554 12.7646 14.5464 13.2548 14.1284 13.6469C13.6981 14.0513 13.1325 14.2535 12.4317 14.2535H12.4132C11.737 14.2535 11.1899 14.0513 10.7718 13.6469C10.3538 13.2425 10.1448 12.734 10.1448 12.1213ZM10.3845 28.2043V15.9261H14.4788V28.2043H10.3845ZM16.7472 28.2043H20.8415V21.3483C20.8415 20.9195 20.8907 20.5886 20.989 20.3558C21.1612 19.9392 21.4224 19.5869 21.7729 19.2989C22.1233 19.011 22.5628 18.867 23.0915 18.867C24.4686 18.867 25.1571 19.7921 25.1571 21.6424V28.2043H29.2513V21.1645C29.2513 19.351 28.821 17.9755 27.9603 17.0381C27.0997 16.1007 25.9624 15.632 24.5485 15.632C22.9624 15.632 21.7267 16.3121 20.8415 17.6722V17.709H20.8231L20.8415 17.6722V15.9261H16.7472C16.7718 16.3182 16.7841 17.5374 16.7841 19.5838C16.7841 21.6302 16.7718 24.5037 16.7472 28.2043Z"
					fill="white"
				/>
			</svg>
		),
		href: "https://www.linkedin.com/company/infopatang/?viewAsMember=true",
	},
	{
		name: "YouTube",
		icon: (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="39"
				height="39"
				viewBox="0 0 39 39"
				fill="none"
			>
				<rect
					x="0.786621"
					y="-0.000488281"
					width="38.2131"
					height="38.2131"
					rx="8.4918"
					fill="#E5485B"
				/>
				<path
					d="M20.3585 26.5148L16.0466 26.4342C14.6505 26.4061 13.2509 26.4622 11.8822 26.1712C9.80003 25.7365 9.65252 23.6052 9.49817 21.8174C9.28549 19.304 9.36782 16.745 9.76918 14.2526C9.99576 12.8541 10.8874 12.0196 12.2664 11.9288C16.9213 11.5992 21.6072 11.6382 26.2518 11.792C26.7424 11.8061 27.2363 11.8831 27.72 11.9708C30.1075 12.3985 30.1658 14.8138 30.3205 16.847C30.4749 18.9012 30.4097 20.9659 30.1147 23.0061C29.878 24.6954 29.4252 26.1119 27.5141 26.2487C25.1197 26.4275 22.7802 26.5715 20.379 26.5256C20.3791 26.5148 20.3654 26.5148 20.3585 26.5148ZM17.8235 22.2381C19.6279 21.1794 21.3979 20.1383 23.192 19.0867C21.3842 18.028 19.6176 16.9869 17.8235 15.9352V22.2381Z"
					fill="white"
				/>
			</svg>
		),
		href: "https://www.youtube.com/",
	},
];
const svg_image = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="300"
		height="31"
		viewBox="0 0 330 31"
		fill="none"
	>
		<path
			d="M24.498 23.5406C21.3235 23.5406 19.9447 20.699 18.4697 17.6958C17.3153 15.3385 16.2572 13.3365 14.7822 12.9812C14.4936 12.8844 14.3332 12.7875 14.3332 12.4969V10.7208C14.3332 10.4625 14.4615 10.3656 14.718 10.2365C18.6621 7.97604 19.9768 0.290625 24.6583 0.290625C26.0371 0.290625 26.8708 0.775 27.5121 1.35625C27.6083 1.45313 27.6404 1.55 27.6404 1.64687C27.6404 1.9375 26.5822 3.4875 26.3578 3.4875C26.2616 3.4875 26.1974 3.45521 26.0371 3.35833C25.6523 3.06771 25.2676 2.87396 24.6583 2.87396C21.9648 2.87396 20.4257 9.33229 17.2512 11.3344V11.4635C18.6621 12.0771 19.6882 14.0792 20.7463 16.275C22.1251 19.0844 23.023 21.1187 24.9469 21.1187C27.1273 21.1187 28.923 17.8896 29.6284 16.049C29.6926 15.8875 29.7567 15.8229 29.885 15.8229C30.1094 15.8229 31.392 16.4365 31.392 16.7271C31.392 16.824 31.36 16.9531 31.3279 17.05C30.6545 19.149 28.2496 23.5406 24.498 23.5406ZM6.7658 23.5406C4.61742 23.5406 2.82176 21.926 2.82176 21.4094C2.82176 21.1187 3.94405 19.6979 4.23264 19.6979C4.32883 19.6979 4.42503 19.7948 4.74568 20.0531C5.1946 20.4729 6.06036 21.1187 6.99026 21.1187C9.97235 21.1187 10.5175 17.7604 10.5175 15.274V4.90833C10.5175 4.2625 10.6137 3.4875 10.774 3.03542H6.862C4.55329 3.03542 2.62936 4.10104 2.62936 6.39375C2.62936 7.87917 3.87992 9.04167 5.32286 9.3C5.48319 9.33229 5.61145 9.39687 5.61145 9.52604C5.61145 9.88125 5.06634 11.1083 4.87394 11.4635C4.80981 11.5927 4.71362 11.6573 4.58535 11.6573C2.82176 11.6573 0 9.55833 0 6.42604C0 2.87396 2.66143 0.613541 6.50928 0.613541H12.6658C13.0506 0.613541 13.2751 0.904167 13.2751 1.38854C13.2751 1.58229 13.211 5.61875 13.211 7.55625V15.274C13.211 19.0198 11.8321 23.5406 6.7658 23.5406Z"
			fill="#FFEEEE"
		/>
		<path
			d="M30.9081 5.55417C29.9461 5.55417 29.2407 4.87604 29.2407 3.81042C29.2407 2.80938 29.9782 2.13125 30.9722 2.13125C31.9021 2.13125 32.6717 2.7125 32.6717 3.74583C32.6717 4.81146 32.0303 5.55417 30.9081 5.55417ZM34.4032 22.9594C31.4211 22.9594 29.5292 20.8927 29.5292 17.4375V9.04167C29.5292 8.58958 29.7216 8.39583 30.1064 8.39583H31.7097C32.1265 8.39583 32.351 8.55729 32.351 9.04167C32.351 10.2688 32.2548 14.6281 32.2548 16.7271C32.2548 19.0521 33.0885 20.5375 34.9483 20.5375C37.1929 20.5375 38.7641 17.7281 39.4054 16.0812C39.4695 15.9198 39.5337 15.8229 39.694 15.8229C39.9505 15.8229 41.2011 16.4365 41.2011 16.7271C41.2011 16.824 41.169 16.9531 41.1369 17.05C40.3032 19.4396 38.1869 22.9594 34.4032 22.9594Z"
			fill="#FFEEEE"
		/>
		<path
			d="M64.9443 15.8229C65.2008 15.8229 66.4513 16.4687 66.4513 16.7271C66.4513 16.824 66.4193 16.9854 66.3872 17.0823C65.5856 19.4073 63.7899 21.3771 61.0964 21.3771C60.5513 21.3771 59.91 21.2479 59.6214 21.0865L59.5573 21.151C59.6855 21.8292 59.8138 22.8625 59.8138 23.8312C59.8138 27.4156 58.2105 31 54.6512 31C51.0279 31 50.098 27.2219 50.098 22.0229V10.7854C50.098 5.16667 52.1181 0 56.7355 0C59.3328 0 61.0964 1.87292 61.0964 4.32708C61.0964 8.10521 57.4409 11.5281 52.7273 12.9167V21.926C52.7273 25.4458 52.9838 28.5781 54.8436 28.5781C56.5752 28.5781 57.4089 26.0271 57.4089 23.5729C57.4089 21.9906 57.1523 20.6344 56.8638 19.7625C55.8377 18.8583 55.036 17.2437 55.036 16.8562C55.036 16.2104 56.4148 15.3062 57.0882 15.3062C57.3127 15.3062 57.4409 15.3708 57.5371 15.5646C58.1143 16.5656 59.2687 19.0521 61.2888 19.0521C62.9562 19.0521 64.0464 17.599 64.6877 16.0812C64.7519 15.8875 64.816 15.8229 64.9443 15.8229ZM56.6393 2.325C54.2344 2.325 52.8235 5.87708 52.7273 10.4625C55.517 9.59062 58.5632 7.07187 58.5632 4.39167C58.5632 3.1 57.8257 2.325 56.6393 2.325Z"
			fill="#FFEEEE"
		/>
		<path
			d="M70.3398 22.9594C66.8767 22.9594 64.2473 20.1177 64.2473 15.7583C64.2473 11.3667 67.0691 8.04062 71.1093 8.04062C73.1294 8.04062 74.7968 8.84792 75.8229 9.81667L75.9191 9.78437V9.00937C75.9191 8.62187 76.1436 8.39583 76.4963 8.39583H78.0675C78.4523 8.39583 78.6768 8.58958 78.6768 9.04167C78.6768 9.65521 78.324 14.8865 78.324 17.4375C78.324 19.2458 78.9974 20.5375 80.3762 20.5375C82.3963 20.5375 83.9355 17.8896 84.7051 16.049C84.7692 15.8875 84.8333 15.8229 84.9616 15.8229C85.186 15.8229 86.4687 16.4365 86.4687 16.7271C86.4687 16.824 86.4366 16.9531 86.4045 17.05C85.6991 19.2135 83.5186 22.9594 79.8311 22.9594C77.5865 22.9594 76.0474 21.6354 76.0474 19.6333C76.0474 19.3427 76.0795 19.0521 76.0795 18.9229L75.9512 18.8906C75.2458 20.8604 73.386 22.9594 70.3398 22.9594ZM70.7887 20.5375C74.4441 20.5375 75.9191 15.4031 75.9191 12.4C75.1496 11.4958 73.5463 10.4625 71.8468 10.4625C69.0892 10.4625 67.0691 12.5937 67.0691 15.8552C67.0691 18.6969 68.7365 20.5375 70.7887 20.5375Z"
			fill="#FFEEEE"
		/>
		<path
			d="M100.868 15.8229C101.156 15.8229 102.407 16.4687 102.407 16.7271C102.407 16.824 102.375 16.9531 102.343 17.05C101.509 19.5687 99.2643 22.9594 95.5126 22.9594C92.8191 22.9594 90.8311 21.1833 90.8311 18.5031C90.8311 16.501 91.793 13.6271 92.5947 11.3667L92.5305 11.2698C91.8251 11.4635 90.6066 11.6896 89.324 11.6896C88.6185 11.6896 87.9772 11.625 87.4001 11.5281V11.9802C87.4001 13.7885 86.887 16.3396 86.0212 17.9542C85.9571 18.1156 85.893 18.1802 85.7968 18.1802C85.4761 18.1802 84.1935 17.5344 84.1935 17.2437C84.1935 17.1792 84.3538 16.7917 84.418 16.5656C84.9952 15.1771 85.3799 13.2396 85.3799 11.625C85.3799 11.399 85.3799 11.1729 85.3479 10.9469C84.8669 10.7208 84.45 10.4625 84.0653 10.1719C83.7767 9.91354 83.6805 9.75208 83.6805 9.55833C83.6805 9.20312 84.6745 7.71771 85.0913 7.71771C85.4761 7.71771 86.4381 9.23542 89.9653 9.23542C92.1457 9.23542 93.5887 8.525 93.9414 8.525C94.2941 8.525 95.5447 9.91354 95.5447 10.2042C95.5447 10.7854 93.5566 15.1448 93.5566 18.0833C93.5566 19.5688 94.4545 20.5375 96.0257 20.5375C98.3023 20.5375 99.9376 17.7927 100.611 16.0812C100.675 15.9198 100.739 15.8229 100.868 15.8229Z"
			fill="#FFEEEE"
		/>
		<path
			d="M106.257 22.9594C102.794 22.9594 100.164 20.1177 100.164 15.7583C100.164 11.3667 102.986 8.04062 107.026 8.04062C109.046 8.04062 110.714 8.84792 111.74 9.81667L111.836 9.78437V9.00937C111.836 8.62187 112.061 8.39583 112.413 8.39583H113.985C114.369 8.39583 114.594 8.58958 114.594 9.04167C114.594 9.65521 114.241 14.8865 114.241 17.4375C114.241 19.2458 114.914 20.5375 116.293 20.5375C118.313 20.5375 119.853 17.8896 120.622 16.049C120.686 15.8875 120.75 15.8229 120.879 15.8229C121.103 15.8229 122.386 16.4365 122.386 16.7271C122.386 16.824 122.354 16.9531 122.322 17.05C121.616 19.2135 119.436 22.9594 115.748 22.9594C113.504 22.9594 111.964 21.6354 111.964 19.6333C111.964 19.3427 111.996 19.0521 111.996 18.9229L111.868 18.8906C111.163 20.8604 109.303 22.9594 106.257 22.9594ZM106.706 20.5375C110.361 20.5375 111.836 15.4031 111.836 12.4C111.067 11.4958 109.463 10.4625 107.764 10.4625C105.006 10.4625 102.986 12.5937 102.986 15.8552C102.986 18.6969 104.654 20.5375 106.706 20.5375Z"
			fill="#FFEEEE"
		/>
		<path
			d="M132.295 22.9594C129.377 22.9594 127.325 20.5375 126.139 18.0187C125.882 17.5021 125.594 16.7271 125.594 16.4365C125.594 16.1781 125.882 16.0812 126.203 15.9521C128.48 15.274 130.5 14.1115 130.5 12.3677C130.5 11.2698 129.794 10.4948 128.608 10.4948C125.017 10.4948 123.285 16.7271 123.285 20.4729V21.926C123.285 22.4427 123.061 22.6365 122.644 22.6365H121.137C120.72 22.6365 120.527 22.3781 120.527 21.8292V1.35625C120.527 0.839584 120.72 0.613541 121.137 0.613541H122.676C123.125 0.613541 123.285 0.871875 123.285 1.25937V7.20104C123.285 9.20312 123.093 12.3354 123.061 13.1104L123.189 13.1427C124.087 10.5271 125.979 8.04062 129.281 8.04062C131.59 8.04062 133.161 9.62292 133.161 11.7865C133.161 14.4344 130.949 16.4365 128.383 17.0823C129.089 18.6 130.788 20.5375 132.648 20.5375C134.797 20.5375 136.432 17.7927 137.137 16.049C137.201 15.8875 137.266 15.8229 137.394 15.8229C137.618 15.8229 138.901 16.4365 138.901 16.7271C138.901 16.824 138.869 16.9531 138.837 17.05C138.035 19.375 135.919 22.9594 132.295 22.9594Z"
			fill="#FFEEEE"
		/>
		<path
			d="M148.631 30.8385C148.214 30.8385 147.99 30.6771 147.99 30.0958V9.20312C147.99 8.62187 148.15 8.39583 148.631 8.39583H150.138C150.587 8.39583 150.747 8.62187 150.747 9.04167C150.747 9.49375 150.555 11.7865 150.491 13.1104L150.619 13.1427C151.517 10.9469 153.313 8.04062 156.711 8.04062C160.463 8.04062 162.547 11.1406 162.547 14.7896C162.547 17.6958 161.169 19.924 159.469 20.8281L159.501 20.9896C162.644 20.6344 165.048 18.6969 166.139 16.0812C166.203 15.9198 166.235 15.8229 166.395 15.8229C166.652 15.8229 167.902 16.4687 167.902 16.7271C167.902 16.824 167.87 16.9854 167.838 17.0823C166.395 20.8927 162.868 22.9594 157.545 22.9594C156.263 22.9594 154.691 22.7656 153.794 22.5073C152.671 22.249 151.581 21.7646 151.581 21.5062C151.581 21.1187 152.126 19.7625 152.447 19.7625C152.671 19.7625 153.697 20.5375 155.108 20.5375C157.673 20.5375 159.758 18.374 159.758 14.9187C159.758 12.3354 158.443 10.4625 156.263 10.4625C152.575 10.4625 150.683 16.501 150.683 21.151C150.683 24.6385 150.747 28.0937 150.747 30.1281C150.747 30.6448 150.555 30.8385 150.106 30.8385H148.631Z"
			fill="#FFEEEE"
		/>
		<path
			d="M171.797 22.9594C168.334 22.9594 165.704 20.1177 165.704 15.7583C165.704 11.3667 168.526 8.04062 172.566 8.04062C174.586 8.04062 176.254 8.84792 177.28 9.81667L177.376 9.78437V9.00937C177.376 8.62187 177.601 8.39583 177.953 8.39583H179.525C179.909 8.39583 180.134 8.58958 180.134 9.04167C180.134 9.65521 179.781 14.8865 179.781 17.4375C179.781 19.2458 180.454 20.5375 181.833 20.5375C183.853 20.5375 185.392 17.8896 186.162 16.049C186.226 15.8875 186.29 15.8229 186.419 15.8229C186.643 15.8229 187.926 16.4365 187.926 16.7271C187.926 16.824 187.894 16.9531 187.862 17.05C187.156 19.2135 184.976 22.9594 181.288 22.9594C179.044 22.9594 177.504 21.6354 177.504 19.6333C177.504 19.3427 177.536 19.0521 177.536 18.9229L177.408 18.8906C176.703 20.8604 174.843 22.9594 171.797 22.9594ZM172.246 20.5375C175.901 20.5375 177.376 15.4031 177.376 12.4C176.607 11.4958 175.003 10.4625 173.304 10.4625C170.546 10.4625 168.526 12.5937 168.526 15.8552C168.526 18.6969 170.193 20.5375 172.246 20.5375Z"
			fill="#FFEEEE"
		/>
		<path
			d="M187.446 5.55417C186.484 5.55417 185.779 4.87604 185.779 3.81042C185.779 2.80938 186.516 2.13125 187.51 2.13125C188.44 2.13125 189.21 2.7125 189.21 3.74583C189.21 4.81146 188.568 5.55417 187.446 5.55417ZM190.941 22.9594C187.959 22.9594 186.067 20.8927 186.067 17.4375V9.04167C186.067 8.58958 186.26 8.39583 186.645 8.39583H188.248C188.665 8.39583 188.889 8.55729 188.889 9.04167C188.889 10.2688 188.793 14.6281 188.793 16.7271C188.793 19.0521 189.627 20.5375 191.486 20.5375C193.731 20.5375 195.302 17.7281 195.943 16.0812C196.008 15.9198 196.072 15.8229 196.232 15.8229C196.489 15.8229 197.739 16.4365 197.739 16.7271C197.739 16.824 197.707 16.9531 197.675 17.05C196.841 19.4396 194.725 22.9594 190.941 22.9594Z"
			fill="#FFEEEE"
		/>
		<path
			d="M210.009 22.9594C207.701 22.9594 205.649 21.3448 205.649 18.7615C205.649 16.7594 205.873 15.0802 205.873 13.175C205.873 11.4958 205.264 10.4625 203.725 10.4625C200.454 10.4625 198.626 16.7917 198.626 20.6021V21.9583C198.626 22.4104 198.402 22.6365 198.017 22.6365H196.478C196.029 22.6365 195.869 22.3781 195.869 21.9583V9.13854C195.869 8.55729 196.061 8.39583 196.478 8.39583H198.017C198.402 8.39583 198.626 8.58958 198.626 9.04167C198.626 9.65521 198.434 12.0125 198.37 13.1104L198.498 13.1427C199.364 10.624 201.384 8.04062 204.462 8.04062C207.22 8.04062 208.727 9.62292 208.727 12.2708C208.727 13.2396 208.502 15.6292 208.502 17.7927C208.502 19.5042 209.176 20.5375 210.555 20.5375C212.607 20.5375 214.178 17.8573 214.915 16.049C214.98 15.8875 215.044 15.8229 215.172 15.8229C215.396 15.8229 216.679 16.4365 216.679 16.7271C216.679 16.824 216.647 16.9531 216.615 17.05C215.909 19.149 213.793 22.9594 210.009 22.9594Z"
			fill="#FFEEEE"
		/>
		<path
			d="M220.617 22.9594C216.93 22.9594 214.429 20.0208 214.429 15.6937C214.429 11.4958 217.154 8.04062 221.291 8.04062C223.343 8.04062 225.171 8.94479 226.229 9.88125L226.357 9.81667V1.29167C226.357 0.807291 226.549 0.613541 226.966 0.613541H228.473C228.826 0.613541 229.083 0.839584 229.083 1.25937C229.083 2.64792 228.666 14.8219 228.666 17.5344C228.666 19.2135 229.275 20.5375 230.782 20.5375C232.866 20.5375 234.438 17.6958 235.143 16.049C235.207 15.8875 235.271 15.8229 235.4 15.8229C235.688 15.8229 236.907 16.4688 236.907 16.6948C236.907 16.7917 236.875 16.9531 236.842 17.05C236.073 19.375 233.764 22.9594 230.301 22.9594C227.8 22.9594 226.229 21.5385 226.229 19.375L226.261 18.8583L226.133 18.826C225.363 20.8927 223.471 22.9594 220.617 22.9594ZM221.034 20.5375C224.305 20.5375 226.101 15.9521 226.101 12.4C225.331 11.4958 223.728 10.4625 222.028 10.4625C219.303 10.4625 217.25 12.626 217.25 15.8229C217.25 18.6969 218.886 20.5375 221.034 20.5375Z"
			fill="#FFEEEE"
		/>
		<path
			d="M240.75 22.9594C237.287 22.9594 234.657 20.1177 234.657 15.7583C234.657 11.3667 237.479 8.04062 241.519 8.04062C243.54 8.04062 245.207 8.84792 246.233 9.81667L246.329 9.78437V9.00937C246.329 8.62187 246.554 8.39583 246.906 8.39583H248.478C248.862 8.39583 249.087 8.58958 249.087 9.04167C249.087 9.65521 248.734 14.8865 248.734 17.4375C248.734 19.2458 249.408 20.5375 250.786 20.5375C252.807 20.5375 254.346 17.8896 255.115 16.049C255.179 15.8875 255.243 15.8229 255.372 15.8229C255.596 15.8229 256.879 16.4365 256.879 16.7271C256.879 16.824 256.847 16.9531 256.815 17.05C256.109 19.2135 253.929 22.9594 250.241 22.9594C247.997 22.9594 246.458 21.6354 246.458 19.6333C246.458 19.3427 246.49 19.0521 246.49 18.9229L246.361 18.8906C245.656 20.8604 243.796 22.9594 240.75 22.9594ZM241.199 20.5375C244.854 20.5375 246.329 15.4031 246.329 12.4C245.56 11.4958 243.956 10.4625 242.257 10.4625C239.499 10.4625 237.479 12.5937 237.479 15.8552C237.479 18.6969 239.147 20.5375 241.199 20.5375Z"
			fill="#FFEEEE"
		/>
		<path
			d="M280.121 22.9594C277.813 22.9594 275.76 21.3448 275.76 18.7615C275.76 16.7594 275.985 15.1448 275.985 13.2073C275.985 11.5281 275.408 10.4625 273.868 10.4625C270.566 10.4625 268.738 16.824 268.738 20.5052V21.9583C268.738 22.4104 268.514 22.6365 268.129 22.6365H266.59C266.141 22.6365 265.98 22.3781 265.98 21.926V1.32396C265.98 0.807291 266.173 0.613541 266.59 0.613541H268.129C268.514 0.613541 268.738 0.839584 268.738 1.29167V7.13646C268.738 8.9125 268.546 12.4 268.481 13.1104L268.61 13.1427C269.475 10.624 271.528 8.04062 274.51 8.04062C277.299 8.04062 278.839 9.55833 278.839 12.3677C278.839 13.3042 278.614 15.6292 278.614 17.7927C278.614 19.5042 279.288 20.5375 280.698 20.5375C282.719 20.5375 284.322 17.7927 285.027 16.049C285.091 15.8875 285.156 15.8229 285.284 15.8229C285.508 15.8229 286.791 16.4365 286.791 16.7271C286.791 16.824 286.759 16.9531 286.727 17.05C286.021 19.149 283.905 22.9594 280.121 22.9594Z"
			fill="#FFEEEE"
		/>
		<path
			d="M290.633 22.9594C287.17 22.9594 284.54 20.1177 284.54 15.7583C284.54 11.3667 287.362 8.04062 291.403 8.04062C293.423 8.04062 295.09 8.84792 296.116 9.81667L296.212 9.78437V9.00937C296.212 8.62187 296.437 8.39583 296.789 8.39583H298.361C298.746 8.39583 298.97 8.58958 298.97 9.04167C298.97 9.65521 298.617 14.8865 298.617 17.4375C298.617 19.2458 299.291 20.5375 300.669 20.5375C302.69 20.5375 304.229 17.8896 304.998 16.049C305.062 15.8875 305.126 15.8229 305.255 15.8229C305.479 15.8229 306.762 16.4365 306.762 16.7271C306.762 16.824 306.73 16.9531 306.698 17.05C305.992 19.2135 303.812 22.9594 300.124 22.9594C297.88 22.9594 296.341 21.6354 296.341 19.6333C296.341 19.3427 296.373 19.0521 296.373 18.9229L296.244 18.8906C295.539 20.8604 293.679 22.9594 290.633 22.9594ZM291.082 20.5375C294.737 20.5375 296.212 15.4031 296.212 12.4C295.443 11.4958 293.839 10.4625 292.14 10.4625C289.382 10.4625 287.362 12.5937 287.362 15.8552C287.362 18.6969 289.03 20.5375 291.082 20.5375Z"
			fill="#FFEEEE"
		/>
		<path
			d="M306.282 5.55417C305.32 5.55417 304.615 4.87604 304.615 3.81042C304.615 2.80938 305.352 2.13125 306.346 2.13125C307.276 2.13125 308.046 2.7125 308.046 3.74583C308.046 4.81146 307.405 5.55417 306.282 5.55417ZM309.777 22.9594C306.795 22.9594 304.904 20.8927 304.904 17.4375V9.04167C304.904 8.58958 305.096 8.39583 305.481 8.39583H307.084C307.501 8.39583 307.725 8.55729 307.725 9.04167C307.725 10.2688 307.629 14.6281 307.629 16.7271C307.629 19.0521 308.463 20.5375 310.323 20.5375C312.567 20.5375 314.138 17.7281 314.78 16.0812C314.844 15.9198 314.908 15.8229 315.068 15.8229C315.325 15.8229 316.575 16.4365 316.575 16.7271C316.575 16.824 316.543 16.9531 316.511 17.05C315.678 19.4396 313.561 22.9594 309.777 22.9594Z"
			fill="#FFEEEE"
		/>
		<path
			d="M322.753 16.0812C322.465 16.0812 322.304 15.8875 322.304 15.5646C322.304 12.8844 327.114 10.301 327.114 6.32917C327.114 4.13333 325.607 2.45417 323.298 2.45417C320.669 2.45417 319.034 4.45625 319.034 6.5875C319.034 8.07292 319.611 9.00937 320.028 9.62292C320.124 9.75208 320.156 9.84896 320.156 9.97812C320.156 10.2688 318.585 11.3344 318.232 11.3344C317.751 11.3344 316.276 9.49375 316.276 6.61979C316.276 3.19687 318.969 0 323.33 0C327.146 0 330 2.61563 330 6.13542C330 10.624 325.703 12.7552 324.645 15.5646C324.517 15.8875 324.389 16.0812 324.1 16.0812H322.753ZM323.427 22.9594C322.401 22.9594 321.695 22.3135 321.695 21.2156C321.695 20.1823 322.433 19.5042 323.491 19.5042C324.485 19.5042 325.19 20.1177 325.19 21.1833C325.19 22.249 324.517 22.9594 323.427 22.9594Z"
			fill="#FFEEEE"
		/>
	</svg>
);
const Mobile = () => {
	return (
		<div className="flex flex-col lg:hidden bg-[#E5485B]">
			<div className="px-8 py-6 border-b border-white flex justify-between text-white">
				{Links.map((k, i) => {
					return (
						<a href={k.href} target="_self">
							{k.field}
						</a>
					);
				})}
			</div>
			<div className="px-8 py-8 border-b border-white text-white">
				<div className="flex flex-col gap-2 ">
					<span className="text-lg ">Embracing the spirit of</span>
					{svg_image}
					<form className="relative w-full flex items-center mt-4">
						<input
							type="email"
							className="border-b w-full py-2 outline-none border-white bg-transparent placeholder-[#FFBCBC]"
							placeholder="Enter email"
						/>
						<button className="text-white absolute right-0">Send</button>
					</form>

					<div className="flex w-full gap-4 justify-between">
						<span>NOW</span>
						<span>SUBSCRIBE NOW</span>
						<span>SUBSCRIBE NOW</span>
					</div>
				</div>
			</div>
			<div className="py-4 flex justify-between items-center flex-col">
				<div className="flex gap-4 items-center">
					{Social.map((k) => (
						<a href={k.href} target="_blank" className="cursor-pointer">
							{k.icon}
						</a>
					))}
				</div>
			</div>
		</div>
	);
}
const Desktop = () => {
	return (
		<div className="pt-16 lg:flex hidden flex-col w-full px-4 lg:px-12 bg-[#E5485B] gap-6 text-white ">
			<img src={patang} className="w-[8rem]" />
			<div className="flex lg:flex-row gap-4 lg:gap-0 flex-col w-full justify-between pb-8">
				<div className="flex flex-col gap-12 max-w-1/3">
					<span className="text-lg ">
						Patang is a platform for desi queers
						<br /> that indulges in celebration of
						<br /> rebellious spirit, curiosities,
						<br /> explorations, questions, insecurities
						<br /> of whoever come may
					</span>
				</div>
				<div className="flex flex-col gap-2 ">
					<span className="text-lg">Embracing the spirit of</span>
					{svg_image}
					<form className="relative w-full flex items-center">
						<input
							type="email"
							className="border-b w-full py-2 outline-none border-white bg-transparent placeholder-[#FFBCBC]"
							placeholder="Enter email"
						/>
						<button className="text-white absolute right-0">Send</button>
					</form>

					<div className="flex w-full gap-4 justify-between">
						<span>NOW</span>
						<span>SUBSCRIBE NOW</span>
						<span>SUBSCRIBE NOW</span>
					</div>
				</div>
				<div className="flex flex-col h-full gap-2 justify-between w-1/5 text-lg ">
					{Links.map((k) => {
						return (
							<a target="_self" href={k.href}>
								{k.field}
							</a>
						);
					})}
				</div>
			</div>
			<div className=" border-t-2 border-white py-4 flex justify-between items-center lg:flex-row flex-col">
				<span className="text-lg font-medium text-center">
					We stand for joy, bonhomie and celebration.
				</span>
				<div className="flex gap-4 items-center">
					{Social.map((k) => (
						<a href={k.href} target="_blank" className="cursor-pointer">
							{k.icon}
						</a>
					))}
				</div>
			</div>
		</div>
	);
}
const Footer = () => {
	return (
		<>
			<Mobile />
			<Desktop/>
			</>
		);    
}
export default Footer;