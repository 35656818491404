import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid/index.js";
import {
  ArrowPathIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  BriefcaseIcon,
} from "@heroicons/react/24/outline/index.js";
import {
  AccordionContent,
  Accordion,
  AccordionItem,
  AccordionTrigger,
} from "./Accordion";
import { Fragment, useState } from "react";
import React from "react";

const solutions = [
  {
    name: "Tokri",
    description:
      "",
    href: "/tokri/",
  },
  {
    name: "Baawre",
    description:
      "",
    href: "/baawre/",
  },
  // {
  //   name: "Mastaane",
  //   description:
  //     "",
  //   href: "/mastaane/",
  // },
];
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
const AccordionItems = [
  {
    icon: (
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.33337 12.5001C1.33337 12.5001 2.16671 11.6667 4.66671 11.6667C7.16671 11.6667 8.83337 13.3334 11.3334 13.3334C13.8334 13.3334 14.6667 12.5001 14.6667 12.5001V2.50008C14.6667 2.50008 13.8334 3.33341 11.3334 3.33341C8.83337 3.33341 7.16671 1.66675 4.66671 1.66675C2.16671 1.66675 1.33337 2.50008 1.33337 2.50008V12.5001ZM1.33337 12.5001V18.3334"
          stroke="#4D566E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    title: "Projects",
    content: solutions,
  },
];
export default function DropDown(props: any) {
  const [imageIndex, setImageIndex] = useState(0);
  return (
		<>
			<Accordion defaultValue="" type="single" collapsible>
				{AccordionItems.map((item, index) => (
					<AccordionItem value={`item-${index}`} className={undefined}>
						<AccordionTrigger
							onClick={() => setImageIndex(index)}
							className="text-black font-medium py-4 px-4 bg-gray-50 border-b hover:bg-gray-100 cursor-pointer"
						>
							<span className="flex text-xl gap-3 items-center">
								{item.title}
							</span>
						</AccordionTrigger>
						<AccordionContent className="text-black">
							<div className="relative lg:ml-8 shadow-sm bg-transparent left-1/2 z-40 w-screen md:max-w-none -translate-x-1/2 lg:-translate-x-1/2 transform px-2 sm:px-0 ">
								<div className="rounded-lg shadow-lg w-full ring-opacity-5 px-1 z-40">
									<div className="relative group-hover:grid rounded gap-6 z-40  bg-white px-0 py-0 lg:px-5 lg:py-2 sm:gap-8 sm:p-0">
										{item?.content?.map((item) => (
											<a
												key={item.name}
												href={item.href}
												className=" flex items-start rounded-lg p-3 transition duration-150 ease-in-out bg-gray-100 hover:bg-gray-50"
											>
												{/* {typeof(item.icon)=="string"?<img
                          src={item.icon}
                          className="h-6 w-6 flex-shrink-0 universal "
                        />:<item.icon
                        className="h-6 w-6 flex-shrink-0 universal"
                        aria-hidden="true"
                      />} */}
												<div className="ml-4">
													<p className="text-regular font-medium text-gray-900">
														{item.name}
													</p>
												</div>
											</a>
										))}
									</div>
								</div>
							</div>
						</AccordionContent>
					</AccordionItem>
				))}
			</Accordion>
			<a
				href="/about-us/"
				target="_self"
				className="text-black font-medium py-4 px-4 bg-gray-50 border-b hover:bg-gray-100 cursor-pointer"
			>
				<span className="flex text-xl gap-3 items-center">About Us</span>
			</a>
		</>
	);
}
