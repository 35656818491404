import React from "react";
import { Fragment, useEffect, useState } from "react";
import patang from "../assets/patang.svg";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid/index.js";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline/index.js";
import DropDown from "./MobileDropdown";
const solutions = [
	{
		name: "Tokri",
		description: "Lorem epsum",
		href: "/tokri/",
		icon: patang,
	},
	{
		name: "Baawre",
		description: "Lorem epsum",
		href: "/baawre/",
		icon: patang,
	},
	// {
	// 	name: "Mastaane",
	// 	description: "Lorem epsum",
	// 	href: "/mastaane/",
	// 	icon: patang,
	// },
];	
const Navbar = (props) => {
	return (
		<>
			<div className="sticky lg:hidden flex w-screen top-0 bg-white z-50 shadow-xl">
				<Popover className=" lg:hidden sticky top-0 z-50 w-full">
					<div className="relative px-4 py-4 sm:px-6  z-50">
						<nav
							className="relative  flex justify-between sm:h-10  items-center overflow-visible"
							aria-label="Global"
						>
							<div className="flex flex-shrink-0 flex-grow items-center lg:flex-grow-0">
								<div className="flex w-full items-center justify-between ">
									<a href="/">
										<span className="sr-only">Your Company</span>
										<img alt="Patang" className="md:h-10 h-8" src={patang} />
									</a>
									<div className="-mr-3 flex items-center  lg:hidden">
										<Popover.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
											<span className="sr-only">Open main menu</span>
											<Bars3Icon className="h-6 w-6" aria-hidden="true" />
										</Popover.Button>
									</div>
								</div>
							</div>
						</nav>
					</div>
					<Transition
						as={Fragment}
						enter="duration-150 ease-out"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="duration-100 ease-in"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Popover.Panel
							focus
							className="absolute inset-x-0 top-0 origin-top-right transform p-1 transition overflow-visible z-50"
						>
							<div className="rounded-sm h-screen bg-white shadow-md ring-1 ring-black ring-opacity-5 relative">
								<div className="flex items-center justify-between px-5 py-2 bg-whit border-b shadow-xl">
									<div>
										<img className="h-8 w-auto " src={patang} alt="Logo" />
									</div>
									<div className="-mr-2">
										<Popover.Button className="inline-flex items-center z-40 justify-center rounded-md border-none outline-none  p-2 text-black ">
											<span className="sr-only">Close main menu</span>
											<XMarkIcon className="h-6 w-6" aria-hidden="true" />
										</Popover.Button>
									</div>
								</div>
								<div className="flex h-max flex-col z-50 bg-white">
									<DropDown />
								</div>
								<a
									href="https://docs.google.com/forms/d/e/1FAIpQLScjltKZkKD2JfQFWgCUUozlN9-ybpxLyFmULY9DjAgOe0E1Pw/viewform"
									target="_blank"
									className="block w-full cursor-pointer relative bg-gray-50 px-5 py-5 text-center font-medium universal hover:bg-gray-100"
								>
									Join Us
								</a>
							</div>
						</Popover.Panel>
					</Transition>
				</Popover>
			</div>
			{/* desktop navbar starts */}
			<div className="sticky hidden lg:flex w-full top-0 bg-white px-12 py-4 z-50 shadow-xl">
				<div className="flex w-1/2 justify-start gap-12 items-center ">
					<img
						src={patang}
						onClick={() => (window.location.href = "/")}
						className="cursor-pointer"
					/>
					<div className="flex gap-4">
						<Popover className="relative lg:z-40 group z-40">
							<>
								<button className="inline-flex text-base font-medium focus:outline-none focus:ring-offset-2">
									<span
										style={{
											width: "max-content",
											color: props.page && "white",
										}}
									>
										Projects
									</span>
									<ChevronDownIcon
										className={
											props.page
												? "ml-2 h-5 w-5 mt-1 text-white group-hover:text-gray-200"
												: "ml-2 h-5 w-5 mt-1 group-hover:text-gray-500"
										}
										aria-hidden="true"
									/>
								</button>
								<div className=" absolute lg:ml-8 shadow-sm group hidden group-hover:mt-0 group-hover:grid bg-transparent left-0 z-40 mt-2 w-screen md:max-w-min -translate-x-1/2 lg:-translate-x-1/4 transform px-2 sm:px-0 ">
									<div className="rounded-lg shadow-lg hidden group-hover:block ring-opacity-5 z-40">
										<div className="relative hidden group-hover:grid rounded mt-2 gap-6 z-40 bg-white px-3 py-6 sm:gap-8 sm:py-4 sm:pr-8 overflow-hidden sm:pl-4">
											{solutions.map((item) => (
												<a
													key={item.name}
													href={item.href}
													className="-m-3 flex items-start rounded-lg pr-8 py-4 pl-0 transition duration-150 ease-in-out hover:bg-gray-50 "
												>
													<div className="ml-4">
														<p className="text-md font-semibold text-gray-900 ">
															{item.name}
														</p>
													</div>
												</a>
											))}
										</div>
									</div>
								</div>
							</>
						</Popover>
						<a
							href="/about-us/"
							className="text-base font-medium whitespace-nowrap"
						>
							About Us
						</a>
					</div>
				</div>
				<div className="flex justify-end w-full items-center ">
					<div className="flex gap-4 items-center">
						{/* <a
							href="/login"
							className="px-8 py-2 rounded-full border hover:bg-[#1A1A1A] hover:font-semibold hover:text-white border-gray-200"
						>
							Login
						</a> */}
						<a
							href="/about-us"
							target="_self"
							className="px-4 py-2 bg-[#E5485B] hover:bg-[#1A1A1A] rounded-full text-white font-semibold"
						>
							Lets talk
						</a>
					</div>
				</div>
			</div>
		</>
	);
}
export default Navbar;