import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import register from "../assets/final.svg";
import MobileRegister from "../assets/mobileRegister.svg";
import calendar from "../assets/calendar.svg";
import letter from "../assets/letter.svg";
import fullimage from "../assets/fullimage.svg";
import secondimage from "../assets/secondimage.svg";
import love from "../assets/love.png";
import Tokri from "../assets/tokri.png";
import quote from "../assets/quote.png";
import line from "../assets/linee.png";
import hero from "../assets/herotemplates.png";
const Baawre = () => {
	return (
		<>
			<Navbar />
			<div className="flex w-screen justify-between h-screen py-32 bg-[#E5485B]">
				<div className="flex flex-col text-white gap-8 px-24">
					<span className="text-xl font-semibold">FEST</span>
					<h1 className="text-9xl font-bold font-sans">Mastaane</h1>
					{/* <div className="border-t border-white flex text-white">
							<div className="border-r border-white py-4 px-6 flex flex-col gap-2">
								<span>SEATS</span>
								<span className="text-4xl"> 20</span>
							</div>
							<div className="border-r border-white py-4 px-6 flex flex-col gap-2">
								<span>DAYS</span>
								<span className="text-4xl"> 20</span>
							</div>
							<div className=" py-4 px-6 pr-20 flex flex-col gap-2">
								<span>COSTS</span>
								<span className="text-4xl">30k</span>
							</div>
						</div> */}
					<span className="text-xl" style={{ fontFamily: "inter" }}>
						It is a festivity of pride and free being, we
						<br /> wish to toast to gay pride, a rebellion
						<br /> that's pure revelry.
					</span>
					<span className="text-xl font-semibold mt-6">
						Network | Celebrate | Drag | Booze
					</span>
					<button className="text-2xl pb-2 border-b border-white w-fit mt-6">
						Apply Now
					</button>
					<div className="absolute left-0 bottom-20">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="135"
							height="67"
							viewBox="0 0 135 67"
							fill="none"
						>
							<path
								d="M1.5239 26.2622C8.12814 37.1564 32.8387 70.9575 50.5618 53.6827C55.5815 48.7899 57.7206 35.1299 48.9387 32.2182C37.2281 28.3355 36.0017 46.3558 40.3562 52.7847C51.8276 69.7208 79.0856 67.39 94.5547 58.4597C107.545 50.9603 115.984 37.1935 121.675 23.7631C124.344 17.463 123.853 10.9512 126.08 4.73823C129.527 -4.87337 129.82 9.52773 131.966 14.6628C136.351 25.1551 128.702 8.33865 127.211 4.71232C124.752 -1.27095 111.872 8.53318 106.429 11.4123"
								stroke="#FFBCBC"
								stroke-width="2"
								stroke-linecap="round"
							/>
						</svg>
					</div>
				</div>
				<div className="w-1/2  flex flex-col justify-end mb-12 ">
					<img src={hero} />
				</div>
			</div>
			<div className="w-full px-12 py-8 relative h-[44rem]">
				<div className=" flex flex-col gap-6 max-w-xl ml-24 mt-8">
					<span className="text-5xl text-black tracking-wide font-semibold ">
						Love is in the Air,
						<br /> so is Patang
					</span>
					<span>
						Log kya sochenge, ye bhi hum
						<br /> sochenge toh log kya sochenge?
					</span>
					<button className="px-4 py-2 w-fit bg-[#E5485B] hover:bg-red-500 rounded-full text-white">
						Register Now
					</button>
				</div>
				<img
					className="absolute top-0 w-fit left-[16rem] h-auto"
					src={register}
				/>
			</div>
			{/* <div className="w-screen bg-[url('./assets/template.svg')] bg-cover py-12 px-24 relative pb-72">
					<div className="flex justify-between">
						<div className="flex justify-between w-full py-12">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="212"
								height="142"
								viewBox="0 0 212 142"
								fill="none"
							>
								<path
									d="M3.40587 139.419C3.40587 139.419 6.50021 109.869 28.5663 93.2109C50.9947 76.2794 92.3769 72.1894 102.393 98.3462C107.781 112.417 105.267 125.057 91.805 131.938C78.3883 138.796 65.0828 134.209 55.6656 122.511C35.5034 97.4657 60.3667 57.3277 88.9939 42.4561C117.679 27.5546 165.676 30.096 174.388 61.1132C178.463 75.6202 175.019 89.3979 161.394 95.9497C148.039 102.372 134.327 98.178 125.255 86.5229C105.899 61.6567 127.269 19.3607 156.578 7.50483C175.715 -0.236341 192.504 1.96597 208.932 14.3722"
									stroke="#E5485B"
									stroke-width="5"
									stroke-linecap="round"
								/>
							</svg>
							<div className="mt-20 pr-20">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="112"
									height="107"
									viewBox="0 0 112 107"
									fill="none"
								>
									<path
										d="M13.6764 3.52051C42.3766 -3.70045 43.6355 45.6112 47.7765 51.2766C51.9174 56.942 79.7582 6.72942 104.693 31.7094C129.627 56.6895 37.2171 106.823 30.3671 103.585C21.8046 99.5389 -15.0237 10.7415 13.6764 3.52051Z"
										stroke="#E5485B"
										stroke-width="5"
										stroke-linecap="round"
									/>
								</svg>
							</div>
						</div>
						<div className="bg-white rounded-2xl p-12 flex flex-col gap-2 w-full">
							<span>RESIDENCY</span>
							<span className="text-4xl font-semibold">GOA 2023</span>
							<span>16 Nov - 19 Nov 20 seats</span>
							<div className="flex justify-between">
								<div className="flex flex-col justify-between">
									<span className="mt-4">
										We invite you to join us in the same Tokri for 3 days
										<br /> as we express and explore ourselves.
									</span>
									<button className="border-b w-fit border-[#E5485B] text-[#E5485B] text-xl py-1">
										Apply now
									</button>
								</div>
								<img src={calendar} className="w-fit bg-cover" />
							</div>
						</div>
					</div>
					<div className="flex justify-between py-20">
						<img src={fullimage} className="w-fit -mt-12" />
						<img src={secondimage} className="w-fit" />
					</div>
					<div className=" flex justify-between bg-transparent absolute left-0 right-0 pb-12">
						<span className="text-5xl font-semibold">R SLOT NOW</span>
						<span className="text-5xl font-semibold">BOOK YOUR SLOT NOW</span>
						<span className="text-5xl font-semibold">BOOK YOUR</span>
					</div>
				</div> */}
			{/* <div className="py-24 px-36 w-full flex gap-20 justify-between bg-[#E5485B]">
					<img src={love} />
					<div className="flex flex-col gap-12 w-1/2">
						<img src={quote} className="w-fit" />
						<span className="text-xl text-white">
							Tokri has been an absolute revelation for me. As a queer
							individual, finding a space where I can be my authentic self
							without any hesitation or judgment has been incredibly empowering.
							The blend of rejuvenating activities, heartfelt stories, and the
							opportunity to connect with like-minded souls has made every
							moment at Tokri unforgettable.
						</span>
						<div className="flex flex-col text-white  ">
							<span className="text-lg">Palak Singh</span>
							<span className="text-md">Wellness Coach</span>
						</div>
					</div>
				</div> */}

			<Footer />
		</>
	);
};
export default Baawre;
