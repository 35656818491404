import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Dropdown({option,fields}) {
	return (
		<Menu as="div" className="relative inline-block text-left w-fit border-none outline-none">
			<div>
				<Menu.Button className="inline-flex outline-none items-center w-full border-none justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-md text-gray-400 hover:bg-gray-50">
                    {option}
					<ChevronDownIcon
						className="-mr-1 h-5 w-5 text-gray-400"
						aria-hidden="true"
					/>
				</Menu.Button>
			</div>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right max-h-[20rem] oveflow-hidden rounded-md bg-white shadow-lg focus:outline-none">
					<div className="py-1 max-h-[20rem] overflow-auto">
						{fields?.map((k, i) => {
							return (
								<Menu.Item>
									{({ active }) => (
										<a
											className={classNames(
												active ? "bg-gray-100 text-gray-900" : "text-gray-700",
												"block px-4 py-2 text-sm"
											)}
										>
											{k}
										</a>
									)}
								</Menu.Item>
							);
						})}
						{/* <form method="POST" action="#">
							<Menu.Item>
								{({ active }) => (
									<button
										type="submit"
										className={classNames(
											active ? "bg-gray-100 text-gray-900" : "text-gray-700",
											"block w-full px-4 py-2 text-left text-sm"
										)}
									>
										Sign out
									</button>
								)}
							</Menu.Item>
						</form> */}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	);
}
