// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export const firebaseConfig = {
	apiKey: "AIzaSyAVR5pmAr__QgTmeysmQSP9uN_jeITBwmU",
	authDomain: "patang-7936f.firebaseapp.com",
	projectId: "patang-7936f",
	storageBucket: "patang-7936f.appspot.com",
	messagingSenderId: "1086376476929",
	appId: "1:1086376476929:web:9964ed4d88a814176b9b00",
	measurementId: "G-KLC2G8QX7B",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
